import React, { useEffect, useRef, useState } from 'react'

import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'

import { languageOptions } from '../OrgPlaylists/data'

import { apiURL, deleteIt, postIt, putIt } from '../../utilities/network'
import ArenaCampaignExpansion from './ArenaCampaignExpansion'
import ManualCampaignExpansion from './ManualCampaignExpansion'

import { useAuth } from '../../hooks/useAuth'
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import Tippy from '@tippyjs/react'
import { ChildrenWithRef } from '../../utilities/ChildrenWithRef'
import { BsCurrencyDollar, BsEyeFill, BsFunnelFill, BsHeartFill, BsSearch } from 'react-icons/bs'
import numberWithCommas from '../../utilities/numberWithCommas'
import dateString from '../../utilities/dateString'

// import useSWR from 'swr'

const calculateAdViews = (rowData) => {
	if (!rowData?.active_date && !rowData?.added_date) return ''
	const adViews = rowData?.data?.ad_views || []
	const addedDate = new Date(rowData?.activeDate || rowData?.added_date)
	const adViewsFromAddedDate = adViews.filter(adView => new Date(adView.date) >= addedDate)
	return adViewsFromAddedDate.reduce((acc, adView) => acc + adView.data, 0)
}

const FeederManager = (props) => {
	const originalData = useRef(null)
	const [tableData, setTableData] = useState([])
	const [activePlaylists, setActivePlaylists] = useState([])
	const [expandedRows, setExpandedRows] = useState(null)
	const [activeIndex, setActiveIndex] = useState(0)
	// const [selectedRow, setSelectedRow] = useState(null)
	const toast = useRef(null)
	const dt = useRef(null)
	const debounceRefs = useRef({})

	const { user } = useAuth()

	const [{ data, error, loading }] = useToast(useAxios(apiURL(`/organization/feeders?org=${user.role}`)), {
		loading: 'Grabbing feeders...',
		success: 'Loaded Feeders.'
	})

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	useEffect(() => {
		console.log(data)
		if (!data || tableData.length !== 0) {
			return
		}

		if (originalData.current === null) {
			originalData.current = data.campaignData
		}
		// Get active playlists
		const playlistListofLists = data?.campaignData?.map(campaign => {
			return [...campaign.growth_playlists.map(playlist => {
				return {
					...playlist,
					feeder: campaign.campaign_name,
					type: 'growth'
				}
			}), ...campaign.maintenance_playlists.map(playlist => {
				return {
					...playlist,
					feeder: campaign.campaign_name,
					type: 'maint'
				}
			})]
		})
		const activePlaylists = playlistListofLists.flat().filter(playlist => playlist.active)

		setTableData(data.campaignData)
		setActivePlaylists(activePlaylists)
	}, [data])

	const header = (
		<div className="table-header">
			{user.role.toUpperCase()} Playlist Feeders
			<div>
				<div style={{ display: 'inline-block' }}>
					<Button label='New Feeder' icon={'pi pi-plus'} className="mr-2 p-button-sm p-button-outlined"
						onClick={async () => {
							console.log('New Feeder')
							const newCampaign = {
								campaign_name: 'New Feeder',
								growth_allocation: 100,
								strategy: 'manual'
							}
							const res = await postIt('/campaign', { orgID: user.role, newCampaignData: newCampaign })
							console.log(res)
							const newTableData = [...tableData]
							newTableData.push(res.data)
							setTableData(newTableData)
						}} />
				</div>
			</div>
		</div>
	)

	const updateCampaignProperty = async (id, field, value) => {
		try {
			// Update local state
			const newTableData = [...tableData]
			const index = newTableData.findIndex(feeder => feeder._id === id)
			newTableData[index][field] = value
			setTableData(newTableData)
			// Update server with debounce
			if (debounceRefs.current[field]) {
				clearTimeout(debounceRefs.current[field])
			}
			debounceRefs.current[field] = setTimeout(async () => {
				await putIt('/campaign', { id, field, value })
				debounceRefs.current[field] = null
				toast.current.show({ severity: 'success', summary: 'Success', detail: `${field} updated.`, life: 3000 })
			}, 500)
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: `Issue, couldn't set ${field}. Please try again.`, life: 3000 })
		}
	}

	return (
		<React.Fragment>
			<div className="p-fluid">
				<div className="page-header">
					<h2>Feeder Manager</h2>
				</div>
				<div style={{ display: 'flex', gap: 8 }}>
					<div style={{ flex: 1 }}>
						<div>
							<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
								<TabPanel header='All Feeders'>
									{/* <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
										<p>Each link is <a href="https://hackertyper.com/">https://api.littlesymphonyrecords.com/redirect/ID/</a> (ex. https://api.littlesymphonyrecords.com/redirect/63868e00df3e3a53480b0995/)</p>
										<p>Click the copy button next to each link to get the redirect link</p>
									</div> */}
									<DataTable
										value={tableData}
										ref={dt}
										header={header}
										removableSort
										resizableColumns
										reorderableColumns
										columnResizeMode="fit"
										sortField={'campaign_name'}
										sortOrder={1}
										expandedRows={expandedRows}
										onRowToggle={(e) => {
											setExpandedRows(e.data)
										}}
										rowExpansionTemplate={rowData => rowData?.strategy === 'arena'
											? <ArenaCampaignExpansion id={rowData._id} playlistData={data?.playlistsInfo} />
											: <ManualCampaignExpansion id={rowData._id} total_spend={rowData.total_spend} playlistData={data?.playlistsInfo} />}
										scrollable
										scrollHeight="flex"
										className="p-datatable-sm"
										emptyMessage="No feeders. Make a new one!"
										loading={loading}
									>
										<Column expander style={{ maxWidth: 50 }} />
										<Column field={'url'} header={'URL'} body={rowData =>
											<Button
												type="button"
												icon={<i className="pi pi-external-link"></i>}
												style={{ width: 45 }}
												onClick={() => {
													navigator.clipboard.writeText(`https://api.littlesymphonyrecords.com/redirect/${rowData?._id}/`)
													toast.current.show({ severity: 'success', summary: 'Success', detail: 'Copied campaign redirect link to clipboard.', life: 3000 })
												}}>
											</Button>
										} style={{ maxWidth: 80 }}
										/>
										<Column field={'campaign_name'} header={'Campaign'} sortable style={{ minWidth: 300 }} body={(rowData) =>
											<InputText value={rowData.campaign_name} onChange={async (e) => {
												await updateCampaignProperty(rowData._id, 'campaign_name', e.target.value)
											}} />
										} />
										<Column field="lang" header="Playlists" style={{ maxWidth: 120 }}
											body={(rowData) => <div>
												<div>{rowData?.growth_playlists?.length || '0'} growth</div>
												<div>{rowData?.maintenance_playlists?.length || '0'} maintenance</div>
											</div>}
										></Column>
										<Column field="lang" header="Language" style={{ maxWidth: 260 }}
											body={(rowData) => <Dropdown value={rowData?.lang || ''} options={languageOptions} optionLabel="label" optionValue="value"
												onChange={async (e) => {
													await updateCampaignProperty(rowData._id, 'lang', e.value)
												}
												} placeholder="None"
												style={{ position: 'relative', width: '100%' }}
											/>}
										></Column>
										<Column field={'growth_allocation'} sortable header={'Growth %'}
											body={(rowData, props) => <InputNumber
												value={rowData?.growth_allocation ?? 100}
												onValueChange={async (e) => {
													await updateCampaignProperty(rowData._id, 'growth_allocation', e.value)
												}}
												suffix="%" />} style={{ maxWidth: 100 }} />
										{/* Delete button column */}
										<Column body={(rowData) => <Button severity='danger' icon={'pi pi-trash'} className="p-button-sm p-button-outlined p-button-danger"
											onClick={async () => {
												console.log('Delete Feeder')
												const res = await deleteIt(`/campaign?id=${rowData._id}&orgID=${user.role}`)
												console.log(res)
												const newTableData = tableData.filter(feeder => feeder._id !== rowData._id)
												setTableData(newTableData)
											}} />
										} style={{ maxWidth: 100 }} />
									</DataTable>
								</TabPanel>
								<TabPanel header='Active Playlists'>
									<DataTable
										value={activePlaylists || []}
										reorderableRows
										reorderableColumns
										removableSort
										resizableColumns
										columnResizeMode="fit"
										scrollable
										scrollHeight="flex"
										className="p-datatable-sm"
										emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
										<Column field="active" header="" style={{ minWidth: 30, marginLeft: 0, marginRight: 0 }} body={rowData => rowData.active ? '🟢' : ''} />
										<Column field="feeder" header="Feeder" sortable
											style={{ minWidth: 160 }} />
										<Column field="name" header="Name" sortable
											body={rowData => <a href={`https://open.spotify.com/playlist/${rowData.id}`} target='_blank' rel="noreferrer">{rowData.name}</a>}
											style={{ minWidth: 300 }} />
										<Column field="type" header="Type" sortable
											style={{ minWidth: 100 }} />
										<Column field="added_date" header="Added" sortable
											style={{ minWidth: 120 }}
											body={rowData => {
												if (!rowData.added_date && !rowData?.active_date) return ''
												const theDate = new Date(rowData?.active_date || rowData?.added_date)
												return theDate ? dateString(theDate) : ''
											}} />
										<Column field="objective" header="Objective" sortable
											style={{ minWidth: 120 }} />
										<Column field="target" header="Target" style={{ minWidth: 80 }}
											body={rowData => {
												return rowData.target || '-'
											}} />
										<Column field="initial_value" header="Start" style={{ minWidth: 80 }}
											body={rowData => numberWithCommas(rowData?.initial_value) ?? ''} />
										<Column field="followers_current"
											header={<Tippy content="Current followers"><ChildrenWithRef><BsHeartFill size={20} /></ChildrenWithRef></Tippy>}
											style={{ minWidth: 80 }}
											body={rowData => {
												if (!rowData?.followers_current) return ''
												return numberWithCommas(rowData.followers_current)
											}} />
										<Column field="ad_views"
											header={<Tippy content="Ad views (max 1 month of data)"><ChildrenWithRef><BsEyeFill size={20} /></ChildrenWithRef></Tippy>}
											style={{ minWidth: 50 }}
											body={rowData => {
												// Aggregate ad views from the added_on date to now
												return calculateAdViews(rowData)
											}} />
										<Column field="ad_conv"
											header={<Tippy content="Ad conversion (new followers / ad views)"><ChildrenWithRef><BsFunnelFill size={20} /></ChildrenWithRef></Tippy>}
											style={{ minWidth: 50 }}
											body={rowData => {
												const adViews = calculateAdViews(rowData)
												if (!rowData?.followers_current || !adViews) {
													return '-'
												}
												if (rowData.data.f_spotify.findIndex(val => val.date === rowData.data.ad_views[0].date) === -1) {
													return <Tippy content="Waiting for latest follower data for accuracy"><span>TBD</span></Tippy>
												}
												return `${Math.round((rowData.followers_current - Math.max(rowData?.followers_start || 0, rowData?.initial_value || 0, rowData.data.f_spotify.find(val => {
													const oneDayLater = new Date(val.date)
													oneDayLater.setDate(oneDayLater.getDate() + 1)
													const oneDayLaterString = dateString(oneDayLater)
													return oneDayLaterString === rowData.data.ad_views[0].date
												}).data)) / adViews * 100)}%`
											}} />
										<Column field="seo_current"
											header={<Tippy content="SEO Rank for name"><ChildrenWithRef><BsSearch size={20} /></ChildrenWithRef></Tippy>}
											style={{ minWidth: 50 }} />
										<Column field="estimated_spend"
											header={<Tippy content="Estimated spend">
												<ChildrenWithRef><BsCurrencyDollar size={20} /></ChildrenWithRef>
											</Tippy>}
											style={{ minWidth: 60 }} body={rowData => rowData?.estimated_spend ? Number(rowData.estimated_spend).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'} />
										<Column field="beans" header="CPR" style={{ minWidth: 60 }} body={rowData =>
											rowData?.estimated_spend && (rowData.current_value - rowData.initial_value) !== 0 ? Math.floor(rowData.estimated_spend / (rowData.current_value - rowData.initial_value) * 100) / 100 : '-'} />
									</DataTable >
								</TabPanel>
								<TabPanel header='History'>

								</TabPanel>
							</TabView>
						</div>
					</div>
				</div>
			</div>
			<Toast ref={toast} />
		</React.Fragment >
	)
}

export default FeederManager
