import { numberWithCommas } from '../../utilities/utils'

const chartOptions = {
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			filter: function (tooltipItem) {
				return tooltipItem.datasetIndex !== 0
			},
			callbacks: {
				title: function (context) {
					if (context.length === 0) {
						return ''
					}
					let label = context[0].label
					const labelArray = label.split(',')
					label = `${labelArray[0]},${labelArray[1]}`
					return label
				},
				label: function (context) {
					if (!context) {
						return ''
					}
					return numberWithCommas(context.parsed.y)
				}
			}
		}
	},
	scales: {
		x: {
			type: 'time',
			time: {
				unit: 'day'
			}
		},
		'y-advertised': {
			position: 'left',
			stepSize: 1,
			max: 0,
			min: 1,
			autoSkip: false,
			display: false
		},
		'y-f_spotify': {
			stepSize: 1,
			grace: 20,
			position: 'right'
		},
		'y-listeners-7day': {
			position: 'left',
			grace: 10
			// grid line settings
			// grid: {
			// drawOnChartArea: false // only want the grid lines for one axis to show up
			// }
		},
		'y-streams-7day': {
			position: 'left',
			grace: 10
			// grid line settings
			// grid: {
			// drawOnChartArea: false // only want the grid lines for one axis to show up
			// }
		},
		'y-seo_name': {
			position: 'right',
			reverse: true,
			stepSize: 1,
			beginAtZero: false,
			min: 1,
			suggestedMax: 50
		}
	},
	animation: false,
	spanGaps: true
}

export default chartOptions
