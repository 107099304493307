import React from 'react'
import logo from '../img/LSR logo.png'

const LoginDenied = (props) => {
	return (
		<div className="App">
			<header className="App-header">
				<h2>Little Symphony Tools</h2>
				<img src={logo} className="App-logo" alt="logo" />
				<h3>
					User denied - if you think this is not right, send Brandon a
					message on Slack.
				</h3>
				<h4>
					If you think this is right, what were you trying to do
					anyway?
				</h4>
			</header>
		</div>
	)
}

export default LoginDenied
