import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Chart } from 'primereact/chart'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { numberWithCommas } from '../utilities/utils'
import { fetcher } from '../utilities/network'
import { Card } from 'primereact/card'

const chartOptions = {
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				label: function (context) {
					return numberWithCommas(context.parsed.y)
				}
			}
		}
	},
	scales: {
		y: {
			stepSize: 1,
			grace: 20,
			beginAtZero: true
		},
		x: {
			type: 'time',
			time: {
				unit: 'month'
			}
		}
	},
	animation: false,
	spanGaps: true,
	responsive: true,
	maintainAspectRatio: false
}

const SpotifyKeywordSearch = (props) => {
	const [loading, setLoading] = useState(false)
	const [kwe, setKwe] = useState([])
	const [history, setHistory] = useState(localStorage.getItem('keywordHistory') ? JSON.parse(localStorage.getItem('keywordHistory')) : [])

	const getNumberOfResults = async () => {
		const theQuery = document.getElementById('query').value
		setLoading(true)
		document.getElementById('search-message').innerText = `Getting search data for ${theQuery}...`
		document.getElementById('query-title').innerText = ''
		const results = await fetcher(`/playlistCount?query=${theQuery}`)
		console.log(results.data)
		document.getElementById('query-title').innerText = theQuery
		const newHistory = [...history, { keyword: theQuery, spotify: results.data.spotify, vol: results.data.kwe.vol, comp: results.data.kwe.competition }]
		setHistory(newHistory)
		setKwe(results.data.kwe)
		localStorage.setItem('keywordHistory', JSON.stringify(newHistory))
		setLoading(false)
	}

	const chartData = {
		datasets: [
			{
				label: kwe.keyword,
				data: kwe?.trend
					? kwe.trend.map((val, i) => {
						return { x: `${val.month} ${val.year}`, y: val.value }
					})
					: [],
				borderColor: 'blue',
				cubicInterpolationMode: 'monotone'
			}
		]
	}

	return <React.Fragment>
		<div style={{ marginTop: 13, padding: 20 }}>
			<div>
				<span className="p-input-icon-left">
					<i className="ri-search-line" />
					<InputText type="search" id="query"
						onKeyPress={(e) => {
							if (e.code === 'Enter') {
								getNumberOfResults()
							}
						}}
						placeholder="Spotify search term" />
				</span>
				<Button
					type="button"
					label="Search"
					style={{ marginLeft: 10 }}
					onClick={getNumberOfResults}></Button>
			</div>
			<div id="loading" style={{ width: '100%', textAlign: 'center', display: loading ? 'block' : 'none' }}>
				<p id="search-message"></p>
			</div>
			<div>
				<h3 id="query-title"></h3>
				<Card>
					<div className="kwe-chart-container" style={{ position: 'relative', height: 300 }}>
						<Chart type="line" data={chartData} options={chartOptions} />
					</div>
				</Card>

				<br />
			</div>
			<div>
				<div className="card">
					<DataTable value={history ? history.reverse() : []} responsiveLayout="scroll" emptyMessage="No search history."
						resizableColumns>
						<Column field="keyword" header="Keyword" style={{ width: 200 }}></Column>
						<Column field="spotify" header="Spotify Results" body={(rowData) => numberWithCommas(rowData.spotify)}></Column>
						<Column field="vol" header="Search Volume"
							body={(rowData) => numberWithCommas(rowData.vol)}
						></Column>
						<Column field="comp" header="Search Competition"></Column>
					</DataTable>
				</div>
			</div>
		</div>
	</React.Fragment>
}

export default SpotifyKeywordSearch
