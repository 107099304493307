import React, { useRef, useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Link, useLocation } from 'react-router-dom'
// import { AutoComplete } from 'primereact/autocomplete'

import { useAuth } from '../../hooks/useAuth'

import axios from 'axios'
// import TrackTable from './TrackTable'
import { useRecoilState } from 'recoil'
import useLocalStorage from 'use-local-storage'
import { editingPlaylistDataState } from '../../utilities/store'
import { postIt, fetcher } from '../../utilities/network'
import useWithToast from '../../hooks/useWithToast'
import useSWR from 'swr'

import './style.scss'

function useQuery () {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

const EditPlaylist = () => {
	const query = useQuery()
	// const originalData = useRef(null)
	const [playlist, setPlaylist] = useRecoilState(editingPlaylistDataState)
	const [parentPlaylists] = useLocalStorage('parentPlaylists', [])
	// const primaries = useRecoilValue(primaryPlaylistsState)
	const [spotifyData, setSpotifyData] = useState({})
	const [parentPlaylist, setParentPlaylist] = useState(playlist?.parent || '')
	const [primary, setPrimary] = useState(playlist?.primary || false)
	const [randomizeParentOrder, setRandomizeParentOrder] = useState(playlist?.randomize_parent_order || false)
	const [songLinkToAdd, setSongLinkToAdd] = useState('')
	const toast = useRef(null)

	const { data, error } = useWithToast(useSWR(`/userPlaylists?id=${query.get('id') || ''}`, fetcher), {
		loading: 'Loading playlist...',
		success: 'Playlist loaded.'
	})

	const stateOptions = [{ label: 'No Primary', value: '' }, ...parentPlaylists.map((val, index) => ({ label: val.name, value: val.id }))]

	const { getSpotifyToken } = useAuth()

	useEffect(() => {
		if (!query.get('id')) {
			return
		}

		// If releases recoil atom is empty, get releases data from Spotify
		async function getSpotifyPlaylist () {
			const accessToken = await getSpotifyToken()
			try {
				// TODO: REPLACE ID WITH RELEASE RADAR ID
				// REPLACE ENDPOINT WITH TRACKS ENDPOINT
				const apiURL = `https://api.spotify.com/v1/playlists/${playlist?.id || query.get('id')}`
				const res = await axios.get(apiURL, {
					headers: {
						Authorization: 'Bearer ' + accessToken,
						'Content-Type': 'application/json'
					}
				})

				if (res.status === 200) {
					console.log('got data', res.data.name)
					setSpotifyData(res.data)
					// const playlist = res.data
				}
			} catch (err) {
				console.log(err)
			}
		}

		getSpotifyPlaylist()
		// If we refresh the page or came here not via the home page, get db data
	}, [])

	useEffect(() => {
		if (!data) {
			return
		}
		if (error) {
			console.log(error)
			return
		}
		console.log(data)
		setPlaylist(data.data)
		setPrimary(data.data.primary)
	}, [data])

	const switchPrimary = async val => {
		setPrimary(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'primary', value: val })
		console.log(res)
	}

	const switchParent = async val => {
		setParentPlaylist(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'parent', value: val })
		console.log(res)
	}

	const switchRandomize = async val => {
		setRandomizeParentOrder(val)
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'randomize_parent_order', value: val })
		console.log(res)
	}

	async function addLeadingTrack () {
		// https://open.spotify.com/track/2AE6p5RRHnLxAllP1zdXPt?si=221f56256b7f4ca1
		// Reject links that don't have Spotify or track
		if (songLinkToAdd.indexOf('spotify') === -1 || songLinkToAdd.indexOf('track') === -1) {
			toast.current.show({ severity: 'warn', summary: 'Bad Track link', detail: 'Go get a Spotify track link please.' })
			return
		}
		const splitLink = songLinkToAdd.split('/')
		const trackID = splitLink[splitLink.length - 1].split('?')[0]
		const newLeadingTrackIDs = playlist?.leading_tracks ? [...playlist.leading_tracks, trackID] : [trackID]
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'leading_tracks', value: newLeadingTrackIDs })
		console.log(res)
		toast.current.show({ severity: 'success', summary: 'Added Leading Track', detail: 'Added leading track.' })
		setPlaylist({ ...playlist, leading_tracks: newLeadingTrackIDs })
	}

	async function removeLeadingTrack (id) {
		const newLeadingTrackIDs = playlist?.leading_tracks ? playlist.leading_tracks.filter(el => el !== id) : []
		const res = await postIt('/updateUserPlaylistProperty', { id: playlist.id, key: 'leading_tracks', value: newLeadingTrackIDs })
		console.log(res)
		toast.current.show({ severity: 'success', summary: 'Removed Leading Track', detail: 'Removed leading track.' })
		setPlaylist({ ...playlist, leading_tracks: newLeadingTrackIDs })
	}

	return <React.Fragment>
		<div className="container p-fluid" style={{ marginTop: 3, padding: 20 }}>
			<div>
				<Link to='/app/my-playlists'><h3>{'< All Playlists'}</h3></Link>
			</div>
			{/* Header */}
			<div style={{ display: 'flex' }}>
				<div style={{ marginRight: 20 }}>
					<img src={playlist?.imgURL || (spotifyData?.images && spotifyData.images[0].url)} style={{ width: 250 }} />
				</div>
				<div>
					<h1 style={{ marginTop: 20, flex: 1 }}><a href={`https://open.spotify.com/playlist/${playlist.id}`} dangerouslySetInnerHTML={{ __html: playlist?.name }}></a></h1>
					<p dangerouslySetInnerHTML={{ __html: spotifyData?.description }}></p>
					{
						// Add dropdown of primary playlists to any non-primary playlist
						!playlist.primary &&
						<Dropdown className="primary-dropdown" value={parentPlaylist} options={stateOptions} onChange={(e) => switchParent(e.value)} placeholder="No primary playlist" />
					}
					<div className="switch-container">
						<div>
							<InputSwitch checked={primary} onChange={(e) => switchPrimary(e.value)} />
						</div>
						<div>
							<p>Primary</p>
						</div>
					</div>
					{
						!playlist.primary &&
						<div className="switch-container">
							<div>
								<InputSwitch checked={randomizeParentOrder} onChange={(e) => switchRandomize(e.value)} />
							</div>
							<div>
								<p>Randomize Parent Order</p>
							</div>
						</div>
					}

				</div>
			</div>

			<div>
				{!primary &&
					<div className='max-800-container'>
						<div>
							<h3>Leading Tracks</h3>
							<div>
								{Object.keys(spotifyData).length > 0 &&
									playlist.leading_tracks?.map((val, i) => {
										const trackIndex = spotifyData.tracks.items.findIndex(el => el.track.id === val)
										const trackInfo = trackIndex > -1
											? spotifyData.tracks.items[trackIndex].track
											: {
												album: {
													images: [{ url: 'https://emby.media/community/uploads/inline/355992/5c1cc71abf1ee_genericcoverart.jpg' }]
												}
											}
										return (
											<div key={val} style={{ display: 'flex', marginBottom: 20 }}>
												<div className="number-col">
													<span>{i + 1}</span>
												</div>
												<img src={trackInfo.album.images[0].url} className="cover-col" />
												<div className="info-col">
													<div>
														<p style={{ marginTop: 0, marginBottom: 4 }}><strong>{trackInfo?.name || val}</strong></p>
														<p style={{ marginTop: 0, marginBottom: 0, fontSize: 14 }}>{trackInfo?.artists ? trackInfo.artists[0].name : ''}</p>
													</div>
												</div>
												<div style={{ marginLeft: 'auto' }}>
													<Button className="p-button-danger" icon="pi pi-times" onClick={() => removeLeadingTrack(val)} />
												</div>
											</div>
										)
									})
								}

							</div>
							{/* Add new track */}
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 20px 0 20px' }}>
									<span>{playlist?.leading_tracks ? playlist.leading_tracks.length + 1 : 1}</span>
								</div>
								<div className="p-inputgroup">
									<InputText value={songLinkToAdd} onChange={(e) => setSongLinkToAdd(e.target.value)} placeholder='Paste song link to add' />
									<Button icon="pi pi-plus" onClick={() => addLeadingTrack()} />
								</div>
							</div>
						</div>
					</div>
				}
				{
					primary &&
					<div className='max-800-container'>
						<h3>Child Playlists</h3>
						{playlist?.children && playlist.children.map(playlist =>
							<div key={playlist.id}>
								<div style={{ display: 'flex', marginBottom: 20 }}>
									<div className="number-col">
										<span></span>
									</div>
									<img className='cover-col' src={playlist.imgURL} />
									<div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<div>
											<p style={{ marginTop: 0, marginBottom: 4 }}><strong>{parentPlaylist}</strong></p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				}
				{
					!primary &&
					<div className='max800container'>
						<h3>Parent Playlist</h3>
						<div style={{ display: 'flex', marginBottom: 20 }}>
							<div className="number-col">
								<span></span>
							</div>
							<img className='cover-col' src={'https://community.mp3tag.de/uploads/default/original/2X/a/acf3edeb055e7b77114f9e393d1edeeda37e50c9.png'} />
							<div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<div>
									<p style={{ marginTop: 0, marginBottom: 4 }}><strong>{parentPlaylist}</strong></p>
								</div>
							</div>
						</div>
					</div>
				}

			</div>
		</div >
		<Toast ref={toast} />
	</React.Fragment >
}

export default EditPlaylist
