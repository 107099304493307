import React from 'react'
import { createRoot } from 'react-dom/client'
import Main from './Main'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { AuthProvider } from './hooks/useAuth'
import { ThemeProvider } from './hooks/themeContext'
import { FacebookProvider } from 'react-facebook'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
	// <React.StrictMode>
	<FacebookProvider appId="386222050169371">
		<RecoilRoot>
			<ThemeProvider>
				<BrowserRouter>
					<AuthProvider>
						<Main />
					</AuthProvider>
				</BrowserRouter>
			</ThemeProvider>
		</RecoilRoot>
	</FacebookProvider>
	// </React.StrictMode>
)
