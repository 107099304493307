import React from 'react'
import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

// Pages
import OrgPlaylists from './OrgPlaylists'
import Dashboard from './Dashboard'
import SpotifyKeywordSearch from './SpotifyKeywordSearch'
import SpotifyKeywordTracking from './SpotifyKeywordTracking'
import AdsManager from './AdsManager'
import DataUpload from './DataUpload'
import Playpaster from './Playpaster'
import PlaylistSearch from './PlaylistSearch'
import LinkManager from './LinkManager'
import UserSettings from './UserSettings'
import UserPlaylists from './UserPlaylists'
import EditPlaylist from './EditPlaylist'
import Tags from './Tags'

// Components
import Sidenav from '../components/Sidenav'
import Topbar from '../components/Topbar'
import ExternalUsersPage from './ExternalUsers'
import ServerLogs from './ServerLogs'
import FeederManager from './Feeders'

const App = (props) => {
	const { path } = useRouteMatch()

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh'
			}}>
			<Toaster position="top-center" reverseOrder={false} containerStyle={{ marginTop: -5 }} />
			<Topbar />
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Sidenav />
				<div className="app-content">
					<Switch>
						<Route
							exact
							path={`${path}/dashboard`}
							component={() => <Dashboard />}
						/>
						<Route
							exact
							path={`${path}/organization-playlists`}
							component={() => <OrgPlaylists />}
						/>
						<Route
							exact
							path={`${path}/my-playlists`}
							component={() => <UserPlaylists />}
						/>
						<Route
							path={`${path}/edit-playlist/`}
							component={() => <EditPlaylist />}
						/>
						<Route
							exact
							path={`${path}/playlistsearch`}
							component={() => <PlaylistSearch />}
						/>
						<Route
							exact
							path={`${path}/external-playlists`}
							component={() => <ExternalUsersPage />}
						/>
						<Route
							exact
							path={`${path}/seo/spotify-keyword-search`}
							component={SpotifyKeywordSearch}
						/>
						<Route
							exact
							path={`${path}/seo/spotify-keyword-tracking`}
							component={SpotifyKeywordTracking}
						/>
						<Route
							exact
							path={`${path}/feeders`}
							component={() => <FeederManager />}
						/>
						<Route
							exact
							path={`${path}/adsmanager`}
							component={() => <AdsManager />}
						/>
						<Route
							exact
							path={`${path}/linkmanager`}
							component={() => <LinkManager />}
						/>
						<Route
							exact
							path={`${path}/playpaster`}
							component={() => <Playpaster />}
						/>
						<Route
							exact
							path={`${path}/dataupload`}
							component={DataUpload}
						/>
						<Route
							exact
							path={`${path}/user`}
							component={UserSettings}
						/>
						<Route
							exact
							path={`${path}/serverlogs`}
							component={ServerLogs}
						/>
						<Route
							exact
							path={`${path}/tags`}
							component={Tags}
						/>
						<Route
							exact
							path={path}
						>
							<Redirect to={`${path}/organization-playlists`} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default App
