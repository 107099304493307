import React, { useState, useEffect } from 'react'
// import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Tooltip } from 'primereact/tooltip'
import { Chips } from 'primereact/chips'
import { MultiSelect } from 'primereact/multiselect'
// import { numberWithCommas } from '../utilities/utils'
import { fetcher, postIt } from '../utilities/network'

import useSWR from 'swr'

const SpotifyKeywordTracking = (props) => {
	const [terms, setTerms] = useState(['Loading...'])
	const [selectedColumns, setSelectedColumns] = useState([])
	const [tableData, setTableData] = useState([])

	const { data, error } = useSWR('/dailysearch', fetcher)

	useEffect(() => {
		document.querySelector('.p-chips-input-token > input').disabled = true
		document.querySelector('.p-chips-multiple-container').style.background = '#EDF1F5'
		if (!data) {
			return
		}
		// Restructure the search data so it works with our table
		let tempTableData = []
		setTerms(data.data.terms)
		setSelectedColumns(data.data.terms)
		for (const searchData of data.data.search) {
			// Don't go forward if it doesn't have any data
			if (!('history' in searchData)) {
				continue
			}
			const placeData = searchData.history[searchData.history.length - 1].results
			for (let i = 0; i < placeData.length; i++) {
				const playlist = placeData[i]
				const tempPlaceData = {
					current: playlist.place
				}
				if (searchData.history.length === 2) {
					const prevPlacement = searchData.history[0].results.find(el => el.id === playlist.id)
					if (prevPlacement) {
						tempPlaceData.previous = prevPlacement.place
					}
				}
				const dataIndex = tempTableData.findIndex(el => el.id === playlist.id)
				if (dataIndex > -1) {
					tempTableData[dataIndex][searchData.query] = tempPlaceData
				} else {
					const tempData = {
						id: playlist.id
					}
					tempData[searchData.query] = tempPlaceData
					tempTableData.push(tempData)
				}
			}
		}
		async function getAsyncStuff () {
			// Go get the names of the playlists for each id
			const namesData = await fetcher('/playlists/namesFromIDs')
			const names = namesData.data
			tempTableData = tempTableData.filter(row => {
				const theOne = names.find(dbData => dbData.id === row.id)
				return theOne !== undefined
			}).map(row => {
				const theOne = names.find(dbData => dbData.id === row.id)
				if (theOne !== undefined) {
					return {
						...row,
						name: theOne.name
					}
				}
				return {
					...row,
					name: 'NOT OUR PLAYLIST'
				}
			})
			setTableData(tempTableData)
		}

		getAsyncStuff()
	}, [data])

	const chipChange = async (e) => {
		setTerms(e.value)
		await postIt('/appInfo', {
			name: 'dailySearchTerms',
			data: e.value
		})
	}

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	function getArrow (queryObj) {
		const THRESHOLD = 4
		if (queryObj === undefined) {
			return <span>-</span>
		}
		if (!('previous' in queryObj)) {
			return <span>{queryObj.current}</span>
		}
		const diff = queryObj.previous - queryObj.current
		if (diff > THRESHOLD) {
			return <span style={{ color: 'green', fontWeight: 'bold' }} className="place"><i className="ri-leaf-line"></i> {queryObj.current} ({queryObj.previous})</span>
		} else if (diff > 0) {
			return <span style={{ color: 'darkgreen' }} className="place" ><i className="ri-arrow-up-s-line"></i> {queryObj.current} ({queryObj.previous})</span>
		} else if (diff < -THRESHOLD) {
			return <span style={{ color: 'red' }} className="place"><i className="ri-emotion-sad-line"></i> {queryObj.current} ({queryObj.previous})</span>
		} else if (diff < 0) {
			return <span style={{ color: 'orange' }} className="place"><i className="ri-arrow-down-s-line"></i> {queryObj.current} ({queryObj.previous})</span>
		}
		return <span>{queryObj.current}</span>
	}

	function countSort (event) {
		const fieldArray = event.field
		const field = fieldArray.split('.')[0]
		const newData = [...tableData]
		newData.sort((data1, data2) => {
			const value1 = field in data1 ? data1[field].current : null
			const value2 = field in data2 ? data2[field].current : null
			let result = null

			if (value1 === null && value2 !== null) {
				result = 1
			} else if (value1 !== null && value2 === null) {
				result = -1
			} else if (value1 === null && value2 === null) {
				result = 0
			} else {
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0
			}
			return (event.order * result)
		})

		return newData
	}

	const onColumnToggle = (event) => {
		const selectedColumns = event.value
		const orderedSelectedColumns = terms.filter(col => selectedColumns.some(sCol => sCol === col))
		setSelectedColumns(orderedSelectedColumns)
	}

	const header = (
		<div style={{ textAlign: 'left' }}>
			<MultiSelect value={selectedColumns} options={terms} onChange={onColumnToggle} style={{ width: '20em' }} />
		</div>
	)

	return <React.Fragment>
		<div className="p-card p-fluid" style={{ marginTop: 10, padding: 20, borderRadius: 10 }}>
			<h2 style={{ marginTop: 0 }}>Daily Search Ranking Tracker</h2>
			<p>Terms you add here will be checked each night around midnight. The positions of our playlists on those searches will be logged.</p>
			<div style={{ maxWidth: 300, marginBottom: 15 }}>
				<span className="p-input-icon-left">
					<i className="pi pi-plus" />
					<InputText id="new-term" placeholder="Add a new search to track" onKeyPress={async (e) => {
						if (e.code === 'Enter') {
							const newTerm = document.getElementById('new-term').value.toLowerCase()
							if (terms.indexOf(newTerm) > -1) {
								document.getElementById('new-term').value = ''
								return
							}
							const oldTerms = terms || []
							const newTerms = [...oldTerms, newTerm]
							setTerms(newTerms)
							await postIt('/appInfo', {
								name: 'dailySearchTerms',
								data: newTerms
							})
							document.getElementById('new-term').value = ''
						}
					}} />
				</span>

			</div>

			<div style={{ display: 'flex', marginBottom: 15 }}>
				<h3 style={{ margin: 5, marginRight: 15 }}>We are tracking:</h3>
				<Chips style={{ flex: 1 }} value={terms} onChange={(e) => chipChange(e)} allowDuplicate={false}
					placeholder={terms && terms.length > 0 ? '' : "You aren't tracking any searches!"} />
				<br />
			</div>

			<div>
				<div className="">
					<DataTable value={tableData} header={header} responsiveLayout="scroll" emptyMessage="Getting info from database..."
						resizableColumns removableSort>
						<Column field="name" header="Name" style={{ fontWeight: 600, maxWidth: 200 }} sortable
							body={(rowData) => (<a href={`https://open.spotify.com/playlist/${rowData.id}`}>{rowData.name}</a>)}
						></Column>
						{/* <Column field="followers" header="Followers"
							body={(rowData) => numberWithCommas(rowData.followers)}></Column> */}
						{terms && selectedColumns.map(val => <Column field={`${val}.current`} header={val} key={val} style={{}}
							body={(rowData) => getArrow(rowData[val])} sortable sortFunction={countSort}
						></Column>)}
					</DataTable>
				</div>
			</div>
		</div>
		<Tooltip target=".place" position="bottom" />
	</React.Fragment>
}

export default SpotifyKeywordTracking
