// Get the hash of the url
export const hash = window.location.hash
	.substring(1)
	.split('&')
	.reduce(function (initial, item) {
		if (item) {
			const parts = item.split('=')
			initial[parts[0]] = decodeURIComponent(parts[1])
		}
		return initial
	}, {})
window.location.hash = ''

export function numberWithCommas (x) {
	if (isNaN(x) || x === null) {
		return '-'
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// Give a description from spotify, look through it for "@", ".com", or similar words
// RETURNS [address, type] (type = email, instagram, twitter, website)
export function getContactInfoFromPlaylistDescription (desc) {
	// Maybe they've actually linked it. What a treat.
	if (desc.indexOf('<a href') > -1) {
		const start = desc.indexOf('http')
		const end = desc.indexOf('">')
		const website = desc.substring(start, end)
		return [website, 'website']
	}

	// Split the description by spaces
	const descArray = desc.split(' ')

	// Let's first check for an @ sign (email, IG handle)
	const atWord = descArray.find(word => word.indexOf('@') > -1)
	if (atWord) {
		// Okay we found one.
		// If it's not at the start, it's probably an email address
		if (atWord.indexOf('@') > 0 && atWord.indexOf('.') > -1) {
			// It's got @ in the middle and a period somewhere. Email fo sho
			return [atWord, 'email']
		}

		// If it's not at the start, it's probably a handle
		if (atWord.indexOf('@') === 0) {
			// Probably instagram, assume until you change this code later
			return [atWord, 'instagram']
		}
	}

	// None of those things
	return [undefined, 'none']
}

export function dateString (date) {
	if (date instanceof Date) {
		return date.toISOString().split('T')[0]
	} else {
		return new Date(date).toISOString().split('T')[0]
	}
}
