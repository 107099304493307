export const defaultSmallMessage = `Hello {first name},

My name is {your name}, and I'm with a group called Little Symphony. Our goal is to promote wellness and nature by recording nature sounds from around the world and combining them with calming ambient music. Our music helps people with sleep, focus, relaxation, and meditation, among other uses.

I came across your playlist "{playlist name}", and I was wondering if you'd consider our music for this list? (Song link below)

Placement on your playlist would help us to spread our message about connecting with nature and promoting wellness.

Thank you for reading this,

{your name}

{song link}`

export const defaultBigMessage = ''
