import React, { useEffect, useRef, useState } from 'react'

import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'

import { languageOptions } from '../OrgPlaylists/data'
import { strategyOptions } from './options'

import { apiURL, putIt } from '../../utilities/network'
import ArenaCampaignExpansion from './ArenaCampaignExpansion'
import ManualCampaignExpansion from './ManualCampaignExpansion'

import { useAuth } from '../../hooks/useAuth'
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'

// import useSWR from 'swr'

const AdsManager = (props) => {
	const originalData = useRef(null)
	const [tableData, setTableData] = useState([])
	const [expandedRows, setExpandedRows] = useState(null)
	const [hideInactive, setHideInactive] = useState(true)
	const toast = useRef(null)
	const dt = useRef(null)

	const { user } = useAuth()

	const [{ data, error }] = useToast(useAxios(apiURL(`/organization/ads?org=${user.role}`)), {
		loading: 'Grabbing campaigns from Facebook...',
		success: 'Loaded campaigns.'
	})

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	useEffect(() => {
		console.log(data)
		if (!data || tableData.length !== 0) {
			return
		}

		// Restructure the data so it works with our table
		let tempTableData = data.campaignData
		if (originalData.current === null) {
			originalData.current = data.campaignData
		}
		if (hideInactive) {
			tempTableData = tempTableData.filter(obj => obj.status === 'ACTIVE')
		}
		setTableData(tempTableData)
	}, [data])

	const header = (
		<div className="table-header">
			{user.role.toUpperCase()} Ad Campaigns
			<div>
				<div style={{ display: 'inline-block' }}>
					<Button label={hideInactive ? 'Active Campaigns Only' : 'All Campaigns'} icon={'pi pi-eye'} className="mr-2 p-button-sm p-button-outlined"
						onClick={() => {
							let tempTableData = [...data.campaignData]
							if (!hideInactive) {
								tempTableData = tempTableData.filter(obj => obj.status === 'ACTIVE')
							}
							setTableData(tempTableData)
							setHideInactive(!hideInactive)
						}} />
				</div>
			</div>
		</div>
	)

	const updateCampaignProperty = async (id, field, value) => {
		// toast.current.show({ severity: 'info', summary: `Updating ${field}...`, detail: 'Please wait...', life: 10000 })
		console.log(id, field, value)
		try {
			const langUpdate = await putIt('/campaign', { campaign_id: id, field, value })
			console.log(langUpdate)
			toast.current.show({ severity: 'success', summary: 'Success', detail: `${field} updated.`, life: 3000 })
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: `Issue, couldn't set ${field}. Please try again.`, life: 3000 })
		}
	}

	console.log(tableData)

	return <React.Fragment>
		<div className="p-fluid">
			<div className="page-header">
				<h2>Facebook Ads Management</h2>
			</div>
			<p>Each link is <a href="https://hackertyper.com/">https://api.littlesymphonyrecords.com/redirect/CAMPAIGN_ID/</a> (ex. https://api.littlesymphonyrecords.com/redirect/10749872349623/)</p>
			<p>Click the copy button next to each link to get the redirect link</p>
			<br />
			<DataTable
				value={tableData}
				ref={dt}
				header={header}
				removableSort
				resizableColumns
				reorderableColumns
				columnResizeMode="fit"
				sortField={'campaign_name'}
				sortOrder={1}
				expandedRows={expandedRows}
				onRowToggle={(e) => {
					setExpandedRows(e.data)
				}}
				rowExpansionTemplate={rowData => rowData?.strategy === 'arena'
					? <ArenaCampaignExpansion campaignID={rowData.campaign_id} playlistData={data?.playlistsInfo} />
					: <ManualCampaignExpansion campaignID={rowData.campaign_id} total_spend={rowData.total_spend} playlistData={data?.playlistsInfo} />}
				scrollable
				scrollHeight="flex"
				className="p-datatable-sm"
				emptyMessage="Loading playlists from database...">
				<Column expander style={{ maxWidth: 50 }} />
				<Column field={'on_off'} header={''} body={rowData =>
					<InputSwitch disabled checked={rowData.status === 'ACTIVE'} />
				} style={{ maxWidth: 80 }} />
				<Column field={'campaign_name'} header={'Campaign'} sortable style={{ minWidth: 300 }} />
				<Column field={'strategy'} header={'Strategy'}
					body={(rowData) => <Dropdown value={rowData?.strategy || 'manual'} options={strategyOptions} optionLabel="label" optionValue="value"
						onChange={async (e) => {
							const newTableData = [...tableData]
							const index = newTableData.findIndex(campaign => campaign.campaign_id === rowData.campaign_id)
							newTableData[index].strategy = e.value
							setTableData(newTableData)
							await updateCampaignProperty(rowData.campaign_id, 'strategy', e.value)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
					style={{ minWidth: 100 }} />
				<Column field="lang" header="Language" style={{ maxWidth: 260 }}
					body={(rowData) => <Dropdown value={rowData?.lang || ''} options={languageOptions} optionLabel="label" optionValue="value"
						onChange={async (e) => {
							const newTableData = [...tableData]
							const index = newTableData.findIndex(campaign => campaign.campaign_id === rowData.campaign_id)
							newTableData[index].lang = e.value
							setTableData(newTableData)
							await updateCampaignProperty(rowData.campaign_id, 'lang', e.value)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
				></Column>
				<Column field={'daily_budget'} sortable header={'Daily Budget'} body={rowData => rowData.daily_budget ? rowData.daily_budget.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'} />
				<Column field={'total_spend'} sortable header={'Total Spend'} body={rowData => rowData.total_spend !== -1 ? Number(rowData.total_spend).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'} />
				<Column field={'growth_allocation'} sortable header={'Growth %'}
					body={(rowData, props) => <InputNumber
						value={rowData?.growth_allocation ?? 100}
						onValueChange={async (e) => {
							const newTableData = [...tableData]
							const dataIndex = tableData.findIndex(campaign => campaign.campaign_id === rowData.campaign_id)
							newTableData[dataIndex].growth_allocation = e.value
							setTableData(newTableData)
							await updateCampaignProperty(rowData.campaign_id, 'growth_allocation', e.value)
						}}
						suffix="%" />} style={{ maxWidth: 100 }} />
				<Column field={'url'} header={'URL'} body={rowData =>
					<Button
						type="button"
						icon={<i className="pi pi-external-link"></i>}
						style={{ marginLeft: 10, width: 45 }}
						onClick={() => {
							navigator.clipboard.writeText(`https://api.littlesymphonyrecords.com/redirect/${rowData.campaign_id}/`)
							toast.current.show({ severity: 'success', summary: 'Success', detail: 'Copied campaign redirect link to clipboard.', life: 3000 })
						}}>
					</Button>
				} style={{ maxWidth: 80 }}
				/>
			</DataTable>
		</div >
		<Toast ref={toast} />
	</React.Fragment >
}

export default AdsManager
