import React, { useState, useEffect, useRef } from 'react'

import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
// Libraries
import Tippy from '@tippyjs/react'
// Components
import RowExpansionTemplate from '../../components/PlaylistExpansion'
// Hooks
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'
import { flagColumnFormat, growthColumnFormat, dataColor, followerFormatting } from '../../hooks/usePlaylistTableFormatting'
import { fireSort, flagSort, followerSort, reverseSort } from '../../hooks/useColumnSorts'
import { growthFactor, flagFactor } from '../../hooks/usePlaylistStats'

// Utilities
import { apiURL, postIt } from '../../utilities/network'
import { ChildrenWithRef } from '../../utilities/ChildrenWithRef'
import numberWithCommas from '../../utilities/numberWithCommas'

// Icons
import { BsCalendar3Event, BsCalendar3Week, BsCalendar3, BsHeart, BsFlagFill } from 'react-icons/bs'
import { AiFillFire } from 'react-icons/ai'

import './style.scss'
import { useAuth } from '../../hooks/useAuth'

const ExternalUsersPage = () => {
	const [addLink, setAddLink] = useState('')
	const [playlists, setPlaylists] = useState([])
	const [expandedRows, setExpandedRows] = useState(null)
	const [flagFactors, setflagFactors] = useState({})
	const [growthFactors, setGrowthFactors] = useState({})

	const growthFactorList = useRef([])
	const toast = useRef(null)

	const { user } = useAuth()

	const [{ data, error }] = useToast(useAxios(apiURL(`/user/${user.spotify_id}/external_playlists`)), { loading: 'Loading playlists from database...' })

	useEffect(() => {
		if (!data) {
			return
		}
		console.log(data)
		setPlaylists(data.playlists.filter(playlist => !playlist?.hide))
		const tempGrowthFactors = {}
		const tempGrowthFactorList = []
		const tempflagFactors = {}
		const tempUserList = []
		for (let i = 0; i < data.playlists.length; i++) {
			const playlist = data.playlists[i]
			if (playlist?.hide) {
				continue
			}
			if (playlist?.data?.f_spotify) {
				// Get growth factors and flag factors
				const gf = growthFactor(playlist)
				const cf = flagFactor(playlist)
				tempGrowthFactors[playlist.id] = gf
				tempflagFactors[playlist.id] = cf
				if (gf.followersDiff > 0) {
					tempGrowthFactorList.push(gf.followersDiff)
				}
				const growthFloatArray = new Float64Array(tempGrowthFactorList)
				growthFactorList.current = growthFloatArray.sort().reverse()
				setGrowthFactors(tempGrowthFactors)
				setflagFactors(tempflagFactors)
			}
			// Add user to list
			const userIndex = tempUserList.findIndex(val => val.owner_name === playlist.owner_name)
			if (userIndex === -1) {
				tempUserList.push({
					owner_name: playlist.owner_name,
					owner_id: playlist.owner_id,
					playlists: [playlist]
				})
			} else {
				tempUserList[userIndex].playlists.push(playlist)
			}
			// Add playlist to user
		}
	}, [data])

	const addExternalUserToDatabase = async () => {
		// https://open.spotify.com/user/31o3uu67e7dccpbmzeo5jtbu7c6a?si=127bb577acf94836
		if (addLink.indexOf('user') === -1) {
			console.log('Not a user link.')
			return
		}
		const splitURL = addLink.split('/')
		const userIndex = splitURL.findIndex(val => val === 'user')
		const almostId = splitURL[userIndex + 1]
		const idToAdd = almostId.split('?')[0]
		try {
			toast.current.show({ severity: 'info', summary: 'Adding user', detail: 'Please hold...', life: 3000 })
			const res = await postIt(`/user/${user.spotify_id}/external_user`, { id: idToAdd })
			console.log(res)
			toast.current.show({ severity: 'success', summary: 'Success', detail: 'User added - refresh to show', life: 3000 })
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Issue, please try again.', life: 3000 })
		}
	}

	const hidePlaylist = async (playlistID) => {
		// toast.current.show({ severity: 'info', summary: 'Hiding playlist...', detail: 'Please wait...', life: 10000 })
		try {
			const res = await postIt('/playlist/hide_external_playlist/', { id: playlistID })
			console.log(res)
			toast.current.show({ severity: 'success', summary: 'Success', detail: 'Playlist hidden - refresh to hide.', life: 3000 })
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Issue, please try again.', life: 3000 })
		}
	}

	return (
		<div style={{ marginTop: 16 }}>
			<Card className='less-padding' style={{ width: '100%' }}>
				<div style={{ display: 'flex', gap: 10 }}>
					<p>Link:</p>
					<InputText style={{ width: 500 }} value={addLink} onChange={(e) => setAddLink(e.target.value)} />
					<Button onClick={() => addExternalUserToDatabase()}>Add User</Button>
				</div>
			</Card>
			<br />
			{data?.users && data.users.map(user => (
				<Card className="less-padding" key={user.owner_id} style={{ marginBottom: 20 }}>
					<div>
						<div style={{ height: 120, display: 'flex' }}>
							<img src={user?.images ? user.images[0].url : ''}
								style={{ height: '100%', borderRadius: '100%', marginRight: 16 }} />
							<div>
								<a href={`https://open.spotify.com/user/${user.id}`}>
									<h1 style={{ margin: 0 }}>{user.display_name}</h1>
								</a>

								<p style={{ margin: 0 }}>Last updated: Jan 23, 2023</p>
								<p style={{ margin: 0 }}>{user?.followers?.total} followers</p>
							</div>
						</div>
						<br />
						<div>
							<DataTable
								value={(playlists && playlists.filter(playlist => playlist.owner_id === user.id)) || []}
								removableSort
								resizableColumns
								columnResizeMode="fit"
								sortField={'data.f_spotify.lastWeek'}
								sortOrder={-1}
								expandedRows={expandedRows}
								onRowToggle={(e) => {
									setExpandedRows(e.data)
								}}
								rowExpansionTemplate={rowData => <RowExpansionTemplate id={rowData.id} />}
								scrollable
								scrollHeight='flex'
								className="p-datatable-sm"
								// virtualScrollerOptions={{ itemSize: 46 }}
								emptyMessage={error ? 'Playlists failed to load - refresh to try again.' : 'Playlists will appear here when loaded.'}
							>
								<Column expander style={{ maxWidth: 50 }} />
								<Column
									field='growth'
									header={<Tippy content="Week over month growth factor"><ChildrenWithRef><AiFillFire size={19} /></ChildrenWithRef></Tippy>}
									body={rowData => growthColumnFormat(rowData, growthFactors, growthFactorList)}
									sortable
									sortFunction={event => fireSort(event, [...playlists], growthFactors)}
									style={{ maxWidth: 80 }}
								>
								</Column>
								<Column
									field='flagFactor'
									header={<Tippy content="Growth Flags"><ChildrenWithRef><BsFlagFill size={19} /></ChildrenWithRef></Tippy>}
									body={rowData => flagColumnFormat(rowData, flagFactors)}
									sortable
									sortFunction={event => flagSort(event, [...playlists], flagFactors)}
									style={{ maxWidth: 70 }}
								>
								</Column>
								<Column
									field='image'
									header=""
									body={rowData => <img src={rowData.image} height={50} />}
									style={{ maxWidth: 80 }}
								>
								</Column>
								<Column
									field="name"
									header="Playlist"
									body={(rowData) => (
										<a
											href={`https://open.spotify.com/playlist/${rowData.id}`}
											target="_blank"
											rel="noreferrer"
											dangerouslySetInnerHTML={{ __html: rowData?.name }}>
										</a>
									)}
									style={{ minWidth: 150 }}
									sortable>
								</Column>
								<Column
									field="description"
									header="Description"
									style={{ minWidth: 400 }}
									body={rowData => <span style={{ fontSize: 12, whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: rowData?.description }}></span>}
									sortable>
								</Column>
								<Column field={'data.f_spotify.today.data'}
									header={<Tippy content="Followers"><ChildrenWithRef><BsHeart size={20} /></ChildrenWithRef></Tippy>}
									body={(rowData) => <span className="tooltipped" data-pr-tooltip="7 Day Listeners" data-pr-position="top">{numberWithCommas(rowData?.data?.f_spotify?.today?.data)}</span>}
									className="follower-column"
									style={{ maxWidth: 100 }}
									sortable
									sortFunction={event => reverseSort(event, [...playlists])}
								>
								</Column>

								<Column
									field={'data.f_spotify.yesterday.data'}
									header={<Tippy content="Follower change since yesterday"><ChildrenWithRef><BsCalendar3Event size={19} /></ChildrenWithRef></Tippy>}
									body={(rowData) => <div style={{ color: dataColor(rowData, 'yesterday') }}>
										{followerFormatting(rowData, 'yesterday')}
									</div>}
									className="follower-column"
									style={{ maxWidth: 80 }}
									sortable
									sortFunction={event => followerSort(event, [...playlists])}
								></Column>
								<Column field={'data.f_spotify.lastWeek.data'}
									header={<Tippy content="Follower change since last week"><ChildrenWithRef><BsCalendar3Week size={19} /></ChildrenWithRef></Tippy>}
									body={(rowData) => <div style={{ color: dataColor(rowData, 'lastWeek') }}>
										{followerFormatting(rowData, 'lastWeek')}
									</div>}
									className="follower-column"
									style={{ maxWidth: 80 }}
									sortable
									sortFunction={event => followerSort(event, [...playlists])}
								></Column>
								<Column
									field={'data.f_spotify.lastMonth.data'}
									header={<Tippy content="Follower change since last month"><ChildrenWithRef><BsCalendar3 size={19} /></ChildrenWithRef></Tippy>}
									body={(rowData) => <div style={{ color: dataColor(rowData, 'lastMonth') }}>
										{followerFormatting(rowData, 'lastMonth')}
									</div>}
									className="follower-column"
									style={{ maxWidth: 80 }}
									sortable
									sortFunction={event => followerSort(event, [...playlists])}
								></Column>
								{/* <Column
									field={'data.seo_name'}
									header='Name SEO'
									body={rowData => {
										const newData = rowData?.data?.seo_name
										if (newData) {
											return rowData?.data?.seo_name?.today?.data || '-'
										}
										return '-'
									}}
									className="follower-column"
									style={{ maxWidth: 150 }}
									sortable
								></Column> */}
								<Column
									field="last_updated"
									header="Last Updated"
									style={{ maxWidth: 200 }}
									body={
										rowData => {
											const lastUpdatedDate = new Date(rowData.last_updated)
											return <span>{Math.floor((Date.now() - lastUpdatedDate.getTime()) / (1000 * 3600 * 24))} days ago</span>
										}
									}
									sortable>
								</Column>
								<Column
									field="hide"
									header=""
									style={{ maxWidth: 60 }}
									body={
										rowData => <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" onClick={() => hidePlaylist(rowData.id)}></Button>
									}>
								</Column>
							</DataTable >
						</div>
					</div>
				</Card>
			))}
			<Toast ref={toast} />
		</div>
	)
}

export default ExternalUsersPage
