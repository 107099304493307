module.exports = function moreThanDaysAgo (date1, date2, days) {
	const day = 1000 * 60 * 60 * 24
	if (!(date1 instanceof Date)) {
		date1 = new Date(date1)
		date2 = new Date(date2)
	}
	const diff = Math.abs(date1 - date2)

	return diff > day * days
}
