import React, { useRef, useState, useEffect } from 'react'
import { LoginButton } from 'react-facebook'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Chip } from 'primereact/chip'

import { fetcher, apiURL, postIt, putIt, deleteIt } from '../../utilities/network'
// Hooks
import useToast from '../../hooks/useToast'
import useAxios from 'axios-hooks'
import { useAuth } from '../../hooks/useAuth'

const today = new Date()
const yesterday = new Date(today)
yesterday.setDate(yesterday.getDate() - 1)

const UserSettings = (props) => {
	const toast = useRef(null)
	const [image, setImage] = useState('')
	const [newTagText, setNewTagText] = useState('')
	const [playlistTags, setPlaylistTags] = useState([])
	const [tagToDelete, setTagToDelete] = useState({})
	const [deleteTagDialog, setDeleteTagDialog] = useState(false)

	const { user } = useAuth()

	const [{ data: orgData, error }] = useToast(useAxios(apiURL(`/organization?user_id=${user.spotify_id}`)), {
		loading: 'Loading user/organization info...',
		success: "Let's go."
	})

	useEffect(() => {
		async function getProfilePic () {
			if (user) {
				const res = await fetcher(`/profile-pic?id=${user.spotify_id}`)
				if (res.status === 200) {
					setImage(res?.data?.image_url)
				}
			}
		}

		getProfilePic()
	}, [user])

	useEffect(() => {
		console.log(orgData)
		if (orgData && orgData?.playlist_tags) {
			setPlaylistTags(orgData.playlist_tags)
		}
	}, [orgData])

	if (error) {
		return <p>{error}</p>
	}

	const onCellEditComplete = async (e) => {
		const { rowData, newValue, field, originalEvent: event } = e
		if (newValue.trim().length > 0) {
			rowData[field] = newValue
			const res = await putIt('/organization/playlist_tag', { org_id: orgData.id, _id: rowData._id, newLabel: newValue })
			console.log(res)
			toast.current.show({ severity: 'success', summary: 'Tag updated', detail: '', life: 3000 })
		} else {
			event.preventDefault()
		}
	}

	const textEditor = (options) => {
		return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
	}

	const addTag = async () => {
		toast.current.show({ severity: 'info', summary: 'Adding tag...', detail: '', life: 3000 })
		if (newTagText.length < 2) {
			toast.current.show({ severity: 'warn', summary: 'Enter tag', detail: 'Tag must be at least 2 letters long', life: 4000 })
			return
		}
		const res = await postIt('/organization/playlist_tag', { org_id: orgData.id, label: newTagText })
		console.log(res)
		if (res.status === 200) {
			orgData.playlist_tags.push(res.data)
			setNewTagText('')
			toast.current.show({ severity: 'success', summary: 'Tag added', detail: '', life: 3000 })
		}
	}

	const deleteTag = async () => {
		const _tags = playlistTags.filter(val => val._id !== tagToDelete._id)
		setPlaylistTags(_tags)
		setDeleteTagDialog(false)
		setTagToDelete({})
		const res = await deleteIt(`/organization/playlist_tag/${tagToDelete._id}`)
		console.log(res)
		toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tag Deleted', life: 3000 })
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => {
					setTagToDelete(rowData)
					setDeleteTagDialog(true)
				}} />
			</React.Fragment>
		)
	}

	const deleteTagDialogFooter = (
		<React.Fragment>
			<Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteTagDialog(false)} />
			<Button label="Yes" icon="pi pi-check" className="p-button" onClick={deleteTag} />
		</React.Fragment>
	)

	async function handleSuccess (response) {
		const res = await postIt('/organization/facebook/', { org_id: orgData.id, fb: { ...response.authResponse } })
		toast.current.show({ severity: 'success', summary: 'Facebook access granted', detail: `Access code valid until ${new Date(res.data.fb_account.expiration_timestamp).toLocaleDateString('en-US')}`, life: 3000 })
		console.log(res)
	}

	function handleError (error) {
		toast.current.show({ severity: 'error', summary: 'Facebook access failed', detail: 'Please try again.', life: 3000 })
		console.log(error)
	}

	return <div style={{ display: 'flex' }}>
		<div className="p-card" style={{ marginTop: 10, padding: 20, flex: 1 }}>
			<div style={{ display: 'flex' }}>
				<img
					alt='Profile Picture'
					src={image || 'https://moonvillageassociation.org/wp-content/uploads/2018/06/default-profile-picture1.jpg'}
					style={{ maxWidth: 200, borderRadius: '100%', marginRight: 40 }}
				/>
				<div>
					<h2>{user.display_name}</h2>
					<p>{orgData?.name}</p>
				</div>
			</div>

		</div>
		<div style={{ width: 20 }}>

		</div>
		<div className="p-card" style={{ marginTop: 10, padding: 20, flex: 1 }}>
			<span>Organization Info</span>
			<h1>{orgData?.name}</h1>
			<h3>Facebook</h3>
			{
				orgData?.fb_account &&
				<div style={{ marginBottom: 10 }}>
					<p style={{ margin: 0 }}><strong>User:</strong> {orgData.fb_account.user_name}</p>
					<p style={{ margin: 0 }}><strong>Access token:</strong> {orgData.fb_account?.access_token && orgData.fb_account?.expiration_timestamp * 1000 > Date.now() ? 'Good' : 'Expired'}</p>
					<p style={{ margin: 0 }}><strong>Expiration Date:</strong> {new Date(orgData.fb_account.expiration_timestamp).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</p>
				</div>
			}
			<LoginButton
				scope="public_profile, ads_read"
				onError={handleError}
				onSuccess={handleSuccess}
				style={{ padding: 12, background: '#1877f2', color: 'white', border: 'none', borderRadius: 5, fontWeight: 'bold', fontFamily: 'Helvetica, Arial, sans-serif' }}
			>
				{!orgData?.fb_account ? 'Link Facebook Ads Account' : 'Renew Facebook Access Token'}
			</LoginButton>
			<h3>Users</h3>
			<ul>
				{
					orgData?.user_ids && orgData.user_ids.map(id => <li key={id}>{id}</li>)
				}
			</ul>
			<h3>Playlist Tags</h3>
			<DataTable value={playlistTags} editMode="cell" className="editable-cells-table" responsiveLayout="scroll"
				style={{ width: 400 }}>
				{/* <Column field='_id' header='_id' style={{ width: 40 }} /> */}
				<Column field='label' header='Label (click to edit)' editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} body={rowData => <Chip label={rowData.label} />} />
				<Column body={actionBodyTemplate} exportable={false} style={{ maxWidth: 50 }}></Column>
			</DataTable>
			<div style={{ marginTop: 10 }}>
				<InputText value={newTagText} onChange={(e) => setNewTagText(e.target.value)} style={{ marginRight: 5 }} />
				<Button onClick={addTag}>
					Add
				</Button>
			</div>
		</div>
		<Dialog visible={deleteTagDialog} style={{ width: '550px' }} header="Confirm Tag Deletion" modal footer={deleteTagDialogFooter} onHide={() => setDeleteTagDialog(false)}>
			<div className="confirmation-content">
				<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem', marginRight: 20 }} />
				<div>
					{tagToDelete && <p>Are you sure you want to delete the tag <b>{tagToDelete.label}</b>?</p>}
					{tagToDelete && <p>This will remove the tag from all playlists.</p>}
				</div>

			</div>
		</Dialog>
		<Toast ref={toast} />
	</div >
}

export default UserSettings
