import axios from 'axios'
import { urls } from './config'

export const fetcher = async (url) => {
	const res = await axios.get(urls.cloudFunctionURL + url)
	return res
}

export const apiURL = (url) => {
	return urls.cloudFunctionURL + url
}

export const postIt = async (url, body) => {
	const res = await axios.post(urls.cloudFunctionURL + url, body)
	return res
}

export const putIt = async (url, body) => {
	const res = await axios.put(urls.cloudFunctionURL + url, body)
	return res
}

export const deleteIt = async (url, body) => {
	const res = await axios.delete(urls.cloudFunctionURL + url)
	return res
}

export async function fetcherFun (url) {
	const res = await axios.get(urls.cloudFunctionURL + url)
	return res
}
