import React, { useRef } from 'react'

export default function HorizontalScroller (props) {
	const divRef = useRef(null)

	// useEffect(() => {
	// divRef.current.addEventListener('wheel', (evt) => {
	// evt.preventDefault()
	// divRef.current.scrollLeft += evt.deltaY
	// })
	// }, [])

	return (
		<div ref={divRef} style={{ display: 'flex', flex: 1, overflowX: 'scroll' }}>
			{props.children}
		</div>
	)
}
