
// Growth tracking metric that tracks weekly growth compared to monthly growth
export const growthFactor = playlist => {
	if (!playlist?.data?.f_spotify?.today) {
		return {}
	}
	const monthAverage = Math.round((playlist.data.f_spotify.lastWeek.data - playlist.data.f_spotify.lastMonth.data) / 21 * 100) / 100
	const weekAverage = Math.round((playlist.data.f_spotify.today.data - playlist.data.f_spotify.lastWeek.data) / 7 * 100) / 100
	return {
		monthAverageFollowers: monthAverage,
		weekAverageFollowers: weekAverage,
		followersDiff: Math.round((weekAverage - monthAverage) * 100) / 100
	}
}

// Big playlist flagging metric that checks for several conditions and counts how many of the conditions the playlist meets
export const flagFactor = playlist => {
	const flags = []
	const monthFollowersIncrease = playlist.data.f_spotify.lastWeek?.data - playlist.data.f_spotify.lastMonth?.data || 0
	const fullMonthFollowersIncrease = playlist.data.f_spotify.today?.data - playlist.data.f_spotify.lastMonth?.data || 0
	const weekFollowersIncrease = playlist.data.f_spotify.today?.data - playlist.data.f_spotify.lastWeek?.data || 0

	// FOLLOWER GROWTH METRICS
	// Last week's growth is greater than last month's growth
	const monthAverage = Math.round(monthFollowersIncrease / 21 * 100) / 100
	const weekAverage = Math.round(weekFollowersIncrease / 7 * 100) / 100
	if (weekAverage > monthAverage) {
		flags.push('Weekly follower growth > last month')
	}
	// No followers gained in most recent month but at least one follower in most recent week
	if (monthFollowersIncrease === 0 && weekFollowersIncrease > 0) {
		flags.push('Newly active follower growth')
	}
	// Over 50 followers gained in last month
	if (fullMonthFollowersIncrease > 50) {
		flags.push('>50 follower gain this month')
	}
	// Over 4% growth over last month
	if (playlist.data.f_spotify.today?.data / playlist.data.f_spotify.lastMonth?.data > 1.04) {
		flags.push('>4% follower growth this month')
	}
	// Over 2% growth over last week
	if (playlist.data.f_spotify.today?.data / playlist.data.f_spotify.lastWeek?.data > 1.02) {
		flags.push('>2% follower growth this week')
	}

	// STREAMS GROWTH
	if ('streams-7day' in playlist.data) {
		const monthStreamsIncrease = playlist.data['streams-7day'].lastWeek?.data - playlist.data['streams-7day'].lastMonth?.data || 0
		const weekStreamsIncrease = playlist.data['streams-7day'].today?.data - playlist.data['streams-7day'].lastWeek?.data || 0
		// 0 streams previous week and any amount of streams in current week
		if (monthStreamsIncrease === 0 && weekStreamsIncrease > 0) {
			flags.push('Dormant awoke (streams)')
		}
		// over 3,000 growth in streams this week
		if (weekStreamsIncrease > 3000) {
			flags.push('>3,000 stream growth since last month')
		}
		// over 10% growth in streams this week
		if (playlist.data['streams-7day'].today?.data / playlist.data['streams-7day'].lastWeek?.data > 1.1) {
			flags.push('>10% stream growth since last week')
		}
		// over 25% growth in streams this month
		if (playlist.data['streams-7day'].today?.data / playlist.data['streams-7day'].lastMonth?.data > 1.25) {
			flags.push('>25% stream growth since last month')
		}
	}

	// LISTENERS GROWTH
	if ('listeners-7day' in playlist.data) {
		const monthListenersIncrease = playlist.data['listeners-7day'].lastWeek?.data - playlist.data['listeners-7day'].lastMonth?.data || 0
		const weekListenersIncrease = playlist.data['listeners-7day'].today?.data - playlist.data['listeners-7day'].lastWeek?.data || 0
		// 0 streams previous week and any amount of streams in current week
		if (monthListenersIncrease === 0 && weekListenersIncrease > 0) {
			flags.push('Dormant awoke (listener)')
		}
		// over 10% growth in listeners this week
		if (playlist.data['listeners-7day'].today?.data / playlist.data['listeners-7day'].lastWeek?.data > 1.1) {
			flags.push('>10% stream growth since last week')
		}
		// over 20% growth in listeners this month
		if (playlist.data['listeners-7day'].today?.data / playlist.data['listeners-7day'].lastMonth?.data > 1.2) {
			flags.push('>20% stream growth since last month')
		}
	}

	return flags
}

// Generate a list to paste into Slack of playlists that should be updated.
export const generateUpdateList = (playlistData) => {
	// toast.loading('Copying...')
	const playlistsByOwner = {}
	let totalCount = 0
	// Compile object of arrays of playlists
	for (const playlist of playlistData) {
		// Check if playlist meets criteria
		// If less than 150 followers and they have grown less than 10 followers in the last month, skip!
		if (playlist.data.f_spotify.today.data < 150 && (playlist.data.f_spotify.today.data - playlist.data.f_spotify.lastMonth.data) < 10) {
			continue
		}
		// If updated in the last 14 days, skip!
		const lastUpdatedDate = new Date(playlist.last_updated)
		if (Math.floor((Date.now() - lastUpdatedDate.getTime()) / (1000 * 3600 * 24)) < 14) {
			continue
		}
		if (Object.prototype.hasOwnProperty.call(playlistsByOwner, playlist.owner_name)) {
			playlistsByOwner[playlist.owner_name].push({ name: playlist.name, id: playlist.id })
		} else {
			playlistsByOwner[playlist.owner_name] = [{ name: playlist.name, id: playlist.id }]
		}
		totalCount++
	}
	// Turn object/arrays into text to copy
	let copyText = ''
	for (const [owner, playlistArray] of Object.entries(playlistsByOwner)) {
		copyText += `${owner}\n`
		for (const playlist of playlistArray) {
			copyText += `- ${playlist.name}: https://open.spotify.com/playlist/${playlist.id} \n`
		}
	}
	copyText += 'Total Playlists: ' + totalCount
	if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(copyText)
	}

	return navigator && navigator.clipboard && navigator.clipboard.writeText
}
