// ProtectedRoute.tsx
import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import { useAuth } from './../hooks/useAuth'

const useQuery = () => new URLSearchParams(useLocation().search)

// We are taking in the component that should be rendered if the user is authed
// We are also passing the rest of the props to the <Route /> component such as
// exact & the path
const ProtectedRoute = ({ component: Component, ...rest }) => {
	// Getting the value from our cool custom hook
	const { user, login } = useAuth()
	const query = useQuery()
	const id = query.get('id')

	return (
		<Route
			{...rest}
			render={(props) => {
				// If the user is authed render the component
				// console.log(user.current)
				if (user && (user.role === 'ls' || user.role === 'lsr')) {
					return <Component {...rest} {...props} />
				} else if (user) {
					return <p style={{ textAlign: 'center' }}>
						<br /><br />
						You have not been granted permission to view this site. If you think this is in error, please contact Brandon.
						<br /><br />
						If you do not know who Brandon is, this is most certainly not an error.
						<br /><br />
						<a href="/login">Login</a>
					</p>
				} else {
					// If they are not then we need to redirect to a public page
					console.log('Attempting to log in')
					login(id)
					return <React.Fragment>
						<p style={{ marginLeft: 20 }} > Logging you in...</p>
					</React.Fragment >

					// return (
					// <Redirect
					// to={{
					// pathname: '/login',
					// state: {
					// from: props.location
					// }
					// }}
					// />
					// )
				}
			}}
		/>
	)
}

export default ProtectedRoute
