
export default function getAcronym (str) {
	const words = str.split(' ') // ["for","your","information"]
	const acr = words.map(word => {
		for (let i = 0; i < word.length; i++) {
			if (word[i].toLowerCase().match(/^[0-9a-z]+$/)) {
				return word[i]
			}
		}
		return ''
	}) // ["f","y","i"]
	return acr.join('').toUpperCase()
};
