import React from 'react'
import { Button } from 'primereact/button'
import logo from '../img/logo.png'
import spotify from '../img/spotify-logo.png'
const { urls } = require('../utilities/config')

const Login = (props) => {
	const local = localStorage.getItem('user')
	console.log(local)

	return (
		<div className="App">
			<header className="App-header">
				<div className="box">
					<div style={{ display: 'flex', marginTop: '25vh', alignItems: 'center', justifyItems: 'center' }}>
						<img src={logo} className="App-logo" alt="logo" />
						<div>
							<h2 style={{ fontWeight: 500 }}>LITTLE <span>SYMPHONY</span></h2>
							<h2>TOOLS SUITE</h2>
						</div>
					</div>

					<a
						className="btn btn--loginApp-link"
						href={`${urls.cloudFunctionURL}/auth/spotify/${local === null ? 'showdialog' : 'hidedialog'}`}>
						<Button className="p-button-raised p-button-rounded">
							<img src={spotify} /><span>Log in with Spotify</span>
						</Button>
					</a>
				</div>
			</header>
		</div>
	)
}

export default Login
