import React, { useState, useRef } from 'react'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Toast } from 'primereact/toast'
import axios from 'axios'
import { fetcher } from '../utilities/network'
import { numberWithCommas } from '../utilities/utils'
import { useAuth } from '../hooks/useAuth'
import { InputNumber } from 'primereact/inputnumber'
import moreThanDaysAgo from '../utilities/moreThanDaysAgo'

import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'

const blacklistedOwners = [
	'5m8bbhb37ic8rqisxf116gklw', // LS
	'peter.macfarlane', // LS Sounds
	'621jmkyy0o5xhlcsxq8pduf5s', // LS Creations
	'i5z9i48gqz993m4z0ofz8c93u', // LSR
	'bidoofslay3r', // David Shoults
	'22dun2uryhkjj54p453v5k7za', // Lauren
	'uvefgsc9awlnsgk180e7vypcw', // Signal Alchemy
	'spotify'
]

const PlaylistSearch = (props) => {
	const [googleLink, setGoogleLink] = useState(localStorage.getItem('googleLink') || '')
	const [searchTerm, setSearchTerm] = useState(localStorage.getItem('playlistSearchTerm') || '')
	const [minFollowers, setMinFollowers] = useState(localStorage.getItem('playlistMinFollowers') || '')
	const [minDaysInactive, setMinDaysInactive] = useState(localStorage.getItem('playlistMinDaysActive') || '')
	const [hiddenPlaylists, setHiddenPlaylists] = useState(localStorage.getItem('playlistsHidden') || [])
	const [playlists, setPlaylists] = useState([])
	const [searchTotal, setSearchTotal] = useState(0)
	const [dataLoading, setDataLoading] = useState(false)
	const [lowFollowerCount, setLowFollowerCount] = useState(0)
	const [updatedRecentlyCount, setUpdatedRecentlyCount] = useState(0)
	const [blacklistedOwnersCount, setBlacklistedOwnersCount] = useState(0)
	const [hiddenCount, setHiddenCount] = useState(0)
	const [offset, setOffset] = useState(0)

	const toast = useRef(null)
	const lfc = useRef(0)
	const urc = useRef(0)
	const playlistsRef = useRef([])

	// const { data, error } = useSWR('/playpasterplaylists', fetcher)
	const { getSpotifyToken } = useAuth()

	// useEffect(() => {
	// if (data) {
	// playlistsInDB.current = data.data.map((val) => val.id)
	// ownersInDB.current = data.data.map((val) => val.owner_id)
	// const newResponses = {}
	// const newNotes = {}
	// for (const item of data.data) {
	// newResponses[item.id] = item?.response
	// newNotes[item.id] = item?.notes
	// }
	// setResponses(newResponses)
	// setNotes(newNotes)
	// }
	// }, [data])

	// if (error) {
	// return <p>Error getting playlists from database</p>
	// }

	// Take a Spotify search query, filter out results, and present what's left
	async function searchTime () {
		if (offset > searchTotal) {
			toast.current.show({
				severity: 'error',
				summary: 'No more playlists',
				detail: 'Try another search term',
				life: 3000
			})
		}
		setDataLoading(true)
		const goodToken = await getSpotifyToken()
		const res = await fetcher(`/searchSpotifyPlaylists?query=${searchTerm}&offset=${offset}&token=${goodToken}`)
		console.log(res)
		let playlistsTemp = res.data.playlists

		setSearchTotal(res.data.total)

		// Sort and filter
		// Filter out hidden
		let OGplaylistsLength = playlistsTemp.length
		playlistsTemp = playlistsTemp.filter(obj => obj?.id ? !hiddenPlaylists.includes(obj?.id) : false)
		setHiddenCount(hiddenCount + OGplaylistsLength - playlistsTemp.length)

		// Filter out old owners
		OGplaylistsLength = playlistsTemp.length
		playlistsTemp = playlistsTemp.filter(obj => obj?.owner?.id ? !blacklistedOwners.includes(obj.owner.id) : false)
		setBlacklistedOwnersCount(blacklistedOwnersCount + OGplaylistsLength - playlistsTemp.length)
		for (let i = 0; i < playlistsTemp.length; i++) {
			const playlist = playlistsTemp[i]
			console.log(i)
			// If they have a picture, add them to the list
			setTimeout(async () => {
				const counts = await addToList(playlist.id)
				setLowFollowerCount(counts[0])
				setUpdatedRecentlyCount(counts[1])
				setPlaylists(counts[2])
			}, 100 * i)
		}
		setDataLoading(false)
	}

	// When you click on a playlist result, search for followers and add it to the list to search on FB
	const addToList = async (id) => {
		const playlistID = id
		let newPlaylist = []
		const accessToken = await getSpotifyToken()
		try {
			const apiURL = `https://api.spotify.com/v1/playlists/${playlistID}`
			const res = await axios.get(apiURL, {
				headers: {
					Authorization: 'Bearer ' + accessToken,
					'Content-Type': 'application/json'
				}
			})

			if (res.status === 200) {
				// console.log('got data', res.data.name)
				const playlist = res.data
				if (playlist.followers.total > minFollowers) {
					let lastTracksRes
					if (playlist.tracks.total > 50) {
						const apiURL = `https://api.spotify.com/v1/playlists/${playlistID}/tracks?limit=50&offset=${playlist.tracks.total - 50}`
						lastTracksRes = await axios.get(apiURL, {
							headers: {
								Authorization: 'Bearer ' + accessToken,
								'Content-Type': 'application/json'
							}
						})
					}

					// Get last updated from returned songs
					const tracks = lastTracksRes?.data?.items ? [...playlist.tracks.items, ...lastTracksRes.data.items] : playlist.tracks.items
					let lastUpdated = new Date('2010-01-01')
					for (let j = 0; j < tracks.length; j++) {
						const addedDate = new Date(tracks[j].added_at)
						if (addedDate > lastUpdated) {
							lastUpdated = addedDate
						}
					}
					// Get days between now and last updated
					const today = new Date()
					const updatedRecently = moreThanDaysAgo(today, lastUpdated, minDaysInactive)
					if (updatedRecently) {
						// Log it
						newPlaylist = {
							id: playlist.id,
							description: playlist.description,
							name: playlist.name,
							owner: playlist.owner.display_name,
							ownerId: playlist.owner.id,
							image: playlist.images[0].url,
							playlist_url: playlist.external_urls.spotify,
							owner_url: playlist.owner.external_urls.spotify,
							followers: playlist.followers.total,
							lastUpdated
						}
						playlistsRef.current = [...playlistsRef.current, newPlaylist]
					} else {
						urc.current = urc.current + 1
						console.log(`${playlist.name} was updated recently.`)
					}
				} else {
					lfc.current = lfc.current + 1
					console.log(`${playlist.name} only had ${playlist.followers.total} followers`)
				}
			} else {
				console.log(res)
			}
		} catch (e) {

		}
		return [lfc.current, urc.current, playlistsRef.current]
	}

	const resultCards = playlists.map((playlist) => {
		return (
			<div
				key={playlist.id}
				style={{ width: '100%', margin: 5, border: '1px solid rgba(0,0,0,0.1)', boxShadow: '2px 0px 5px rgba(0,0,0,0.1)', padding: 10, opacity: hiddenPlaylists.includes(playlist.id) ? 0.4 : 1 }}>
				<div style={{ display: 'flex' }} className="result-card">
					<div>
						<img
							src={playlist.image}
							alt="FB pic not loading - disable Tracking Protection in Firefox"
							style={{ width: 230, marginRight: 20, cursor: 'pointer' }}
							id={playlist.id}
							onClick={addToList}
						/>
					</div>
					<div>
						<h3><a href={playlist.playlist_url} target="_blank" rel="noreferrer">
							{playlist.name}
						</a></h3>
						<p><a href={playlist.owner_url} target="_blank" rel="noreferrer">
							by {playlist.owner}
						</a></p>
						<p style={{ opacity: 0.7, marginTop: 15 }}>{`${numberWithCommas(playlist.followers)} followers`}</p>

						<p style={{ opacity: 0.7, marginBottom: 20 }}>
							Last updated: {`${Math.round(Math.abs(playlist.lastUpdated - new Date()) / (1000 * 60 * 60 * 24))} days ago`}
						</p>
						{/* <p>
							Date: {playlist.lastUpdated.toISOString().substring(0, 10)}
						</p> */}
						<Button onClick={() => {
							const text = `${searchTerm}\t${playlist.name}\t${playlist.playlist_url}\t${playlist.owner}\t${playlist.owner_url}\t${playlist.followers}`
							const textArea = document.createElement('textarea')
							textArea.style.position = 'fixed'
							textArea.style.top = 0
							textArea.style.left = 0
							textArea.style.width = '2em'
							textArea.style.height = '2em'
							textArea.style.padding = 0
							textArea.style.border = 'none'
							textArea.style.outline = 'none'
							textArea.style.boxShadow = 'none'
							textArea.style.background = 'transparent'
							textArea.value = text
							document.body.appendChild(textArea)
							textArea.focus()
							textArea.select()
							try {
								const successful = document.execCommand('copy')
								const msg = successful ? 'successful' : 'unsuccessful'
								console.log('Copying text command was ' + msg)
							} catch (err) {
								console.log('Oops, unable to copy')
							}

							document.body.removeChild(textArea)
							toast.current.show({
								severity: 'info',
								summary: 'Copied to Clipboard',
								detail: 'Woohoo!',
								life: 3000
							})
							const newHiddenPlaylists = [...hiddenPlaylists, playlist.id]
							setHiddenPlaylists(newHiddenPlaylists)
							localStorage.setItem('playlistsHidden', newHiddenPlaylists)
						}}>Copy</Button>
						<Button className="p-button-warning" style={{ marginLeft: 10 }}
							onClick={() => {
								const newHiddenPlaylists = [...hiddenPlaylists, playlist.id]
								setHiddenPlaylists(newHiddenPlaylists)
								localStorage.setItem('playlistsHidden', newHiddenPlaylists)
							}}>
							Hide
						</Button>
					</div>
				</div>
			</div>
		)
	})

	// const updateMessageHistory = async (rowData, field, value) => {
	// const newData = { ...rowData }
	// newData[field] = value
	// debounceLastKeystroke.current = Date.now()
	// setTimeout(async () => {
	// if (Date.now() - debounceLastKeystroke.current > 1000) {
	// await postIt('/updatePlaypasterPlaylist', { newData })
	// toast.current.show({
	// severity: 'success',
	// summary: `${field} synced to database`,
	// detail: `${field} for ${rowData.playlist_name} updated.`,
	// life: 3000
	// })
	// }
	// }, 1000)
	// }

	return (
		<div style={{ marginTop: 20 }}>
			<Card style={{ paddingLeft: 20 }}>
				<div className="field col-12 md:col-4">
					<div className="p-inputgroup">
						<span className="p-inputgroup-addon">
							<BsFileEarmarkSpreadsheet></BsFileEarmarkSpreadsheet>
						</span>
						<InputText id="inputgroup" type="text" value={googleLink} placeholder="Google Sheet Link" onChange={(e) => {
							localStorage.setItem('googleLink', e.target.value)
							setGoogleLink(e.target.value)
						}} style={{ maxWidth: 300 }} />
						<a href={googleLink} target="_blank" rel="noreferrer">
							<Button>Open</Button>
						</a>

					</div>
				</div>
				<h3 style={{ marginTop: 20 }}>Search for playlists on Spotify</h3>

				<div className='p-fluid grid formgrid'>
					<div className="field col-12 md:col-4">
						<p style={{ marginBottom: 4 }}>Search Term</p>
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-search"></i>
							</span>
							<InputText id="inputgroup" type="text" value={searchTerm} onChange={(e) => {
								localStorage.setItem('playlistSearchTerm', e.target.value)
								setSearchTerm(e.target.value)
								setSearchTotal(0)
								setOffset(0)
							}
							} style={{ maxWidth: 300 }} />
						</div>
					</div>
					<div className="field col-12 md:col-4">
						<p style={{ marginBottom: 4 }}>Min follower count</p>
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-user"></i>
							</span>
							<InputNumber id="inputgroup" type="text" value={minFollowers} onChange={(e) => {
								localStorage.setItem('playlistMinFollowers', e.value)
								setMinFollowers(e.value)
							}
							} style={{ maxWidth: 300 }} />
						</div>
					</div>
					<div className="field col-12 md:col-4">
						<p style={{ marginBottom: 4 }}>Min days inactive</p>
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-calendar"></i>
							</span>
							<InputNumber id="inputgroup" type="text" value={minDaysInactive} onChange={(e) => {
								localStorage.setItem('playlistMinDaysActive', e.value)
								setMinDaysInactive(e.value)
							}} style={{ maxWidth: 300 }} />
						</div>
					</div>
					<div className="field col-12 md:col-4">
						<p style={{ marginBottom: 4 }}>Offset</p>
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-calendar"></i>
							</span>
							<InputNumber id="inputgroup" type="text" value={offset} onChange={(e) => {
								setOffset(e.value)
							}} style={{ maxWidth: 300 }} />
						</div>
					</div>
				</div>
				<br />
				<Button onClick={() => searchTime()}>Search</Button>
				<div>
					<p style={{ display: offset > 0 ? 'block' : 'none' }}>
						Gathering 50 playlists out of {numberWithCommas(searchTotal)}...
					</p>
				</div>
				<h3>Check out these playlists</h3>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap',
						borderRadius: 20,
						marginTop: 20
					}}>
					{resultCards}
				</div>
				<ProgressSpinner
					style={{ display: dataLoading ? 'block' : 'none' }}
				/>
				<br />
				<Button onClick={() => {
					setOffset(offset + 50)
					searchTime()
				}}>Get next 50 playlists</Button>
				<p>Total playlists on Spotify: {searchTotal}</p>
				<p>Playlists grabbed: {searchTotal > offset + 50 ? offset + 50 : searchTotal}</p>
				<p>Blacklisted owners: {blacklistedOwnersCount}</p>
				<p>Previously hidden: {hiddenCount}</p>
				<p>Low followers: {lowFollowerCount}</p>
				<p>Updated recently: {updatedRecentlyCount}</p>
				<p>Good: {(searchTotal > offset + 50 ? offset + 50 : searchTotal) - lowFollowerCount - updatedRecentlyCount - blacklistedOwnersCount}</p>
				<br />
				<Button className="p-button-danger"
					onClick={() => {
						localStorage.removeItem('playlistsHidden')
					}}>Unhide all hidden playlists</Button>
			</Card>
			{/**
				<TabPanel header="Message History">
					<div style={{ flex: 1 }}>
						<DataTable value={data ? data.data : []}
							emptyMessage="Loading from database..."
							resizableColumns
							columnResizeMode="fit"
							removableSort
							scrollable
							scrollHeight="flex"
							className="p-datatable-sm"
						>
							<Column field="playlist_name" header="Playlist" style={{ fontWeight: 600 }} sortable
								body={(rowData) => (<a href={`https://open.spotify.com/playlist/${rowData.id}`} target="_blank" rel="noreferrer">{rowData.playlist_name}</a>)}
							></Column>
							<Column field="followers" header={<BsHeart />} style={{ maxWidth: 80 }} sortable
								body={(rowData) => numberWithCommas(rowData.followers)}
							></Column>
							<Column field="owner_name" header="Owner" style={{ maxWidth: 200 }} sortable
								body={(rowData) => (<a href={`https://open.spotify.com/user/${rowData.owner_id}`} target="_blank" rel="noreferrer">{rowData.owner_name}</a>)}
							></Column>
							<Column field="ls_rep" header="Rep" style={{ maxWidth: 100 }} sortable
							></Column>
							<Column field="status" header="Status" style={{ maxWidth: 120 }} sortable
							></Column>
							<Column field="date" header="Date" style={{ maxWidth: 120 }} sortable
							></Column>
							<Column field="social_url" header="Social" style={{ maxWidth: 120 }} sortable
								body={(rowData) => <a href={rowData.social_url} target="_blank" rel="noreferrer">{rowData.social_url === '' ? '' : (rowData.social_url.indexOf('facebook') > -1 ? 'Facebook' : 'Instagram')}</a>}
							></Column>
							<Column field="notes" header="Notes" style={{ maxWidth: 450 }} sortable
								body={rowData => <InputText type="text" value={notes[rowData.id]} onChange={(e) => {
									const newNotes = { ...notes }
									newNotes[rowData.id] = e.target.value
									setNotes(newNotes)
									updateMessageHistory(rowData, 'notes', e.target.value)
								}
								} />}
							></Column>
						</DataTable>
					</div>
				</TabPanel>
*/}

			<Toast ref={toast} />
		</div >
	)
}

export default PlaylistSearch
