
export const growthObjectiveOptions = [
	{ label: 'until followers reaches', value: 'followers' },
	{ label: 'until search rank reaches', value: 'searchRank' },
	{ label: 'until YYYY-MM-DD', value: 'endDate' },
	{ label: 'forever + ever', value: 'none' }
]

export const maintenanceObjectiveOptions = [
	{ label: 'followers drop below', value: 'minimum followers' },
	{ label: 'search rank is less than', value: 'searchRank' },
	{ label: 'forever + ever', value: 'none' }
]

export const strategyOptions = [
	{ label: 'Manual', value: 'manual' },
	{ label: 'Arena', value: 'arena' }
]
