import React, { useRef, useState } from 'react'
import logo from '../img/logo.png'
import { Button } from 'primereact/button'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { useAuth } from '../hooks/useAuth'
import { ThemeContext } from '../hooks/themeContext'
import { Toast } from 'primereact/toast'

import { BsMoon, BsSun, BsCloudDownload } from 'react-icons/bs'
import { MdAutoFixHigh, MdOutlineCheckCircleOutline } from 'react-icons/md'
import axios from 'axios'
import { clearCache } from 'axios-hooks'
import { apiURL } from '../utilities/network'
import Tippy from '@tippyjs/react'
import { ChildrenWithRef } from '../utilities/ChildrenWithRef'

const Topbar = (props) => {
	const { logout, user, getSpotifyToken } = useAuth()
	const toast = useRef(null)
	const [bugs, setBugs] = useState(true)

	const { darkMode, toggleFunction } = React.useContext(ThemeContext)

	async function refreshSpotifyPlaylists () {
		const token = await getSpotifyToken()
		toast.current.show({ severity: 'info', summary: 'Getting new playlists from Spotify...', detail: 'Please wait...', life: 5000 })
		try {
			const res = await axios.get(apiURL(`/organization/pullnewplaylists?user_id=${user.spotify_id}&token=${token}`))
			toast.current.show({ severity: 'success', summary: 'Success', detail: `${res.data.created} new playlists sycned with database.`, life: 3000 })
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: 'Issue, please try again.', life: 3000 })
		}
		// Clear the cache so new stuff comes on next request
		clearCache()
	}

	return (
		<div className={`topbar ${!darkMode.value && 'topbar-dark'}`}>
			<div style={{ display: 'flex' }}>
				<img src={logo} className="topbar-logo" alt="logo" />
				<div>
					<h2 style={{ fontWeight: 500 }}>LITTLE <span >SYMPHONY</span></h2>
					<h2 >TOOLS SUITE</h2>
				</div>
				<div style={{ marginLeft: 'auto', marginRight: 32, marginTop: 6 }}>
					<Button
						type="button"
						icon={<Tippy content={bugs ? 'Fix issues with code' : 'Re-introduce errors'}><ChildrenWithRef>{bugs ? <MdAutoFixHigh /> : <MdOutlineCheckCircleOutline />}</ChildrenWithRef></Tippy>}
						className="p-button-secondary p-button-outlined"
						style={{ marginLeft: 10, paddingTop: 8, borderRadius: 7, paddingLeft: 4, paddingRight: 0, background: darkMode.value ? '#efefef' : 'inherit' }}
						onClick={() => {
							if (bugs) {
								toast.current.show({ severity: 'info', summary: 'Fixing bugs...', detail: 'Please wait...', life: 5000 })
								setTimeout(() => {
									toast.current.show({ severity: 'success', summary: 'Bugs Fixed', detail: 'Everything should work perfectly now.', life: 3000 })
									setBugs(!bugs)
								}, 3000)
							} else {
								setBugs(!bugs)
							}
						}}></Button>
					<Button
						type="button"
						icon={<Tippy content="Get new playlists from Spotify"><ChildrenWithRef><BsCloudDownload /></ChildrenWithRef></Tippy>}
						className="p-button-secondary p-button-outlined"
						style={{ marginLeft: 10, borderRadius: 7, paddingLeft: 6, paddingRight: 0, background: darkMode.value ? '#efefef' : 'inherit' }}
						onClick={() => refreshSpotifyPlaylists()}></Button>
					<Button
						type="button"
						icon={!darkMode.value ? <BsSun /> : <BsMoon />}
						className="p-button-secondary p-button-outlined"
						style={{ marginLeft: 10, borderRadius: 7, paddingLeft: 6, paddingRight: 0, background: darkMode.value ? '#efefef' : 'inherit' }}
						onClick={() => toggleFunction()}></Button>
					<Button
						type="button"
						icon={<RiLogoutCircleRLine />}
						className="p-button-secondary p-button-outlined"
						label="Log out"
						style={{ marginLeft: 10, borderRadius: 7, background: darkMode.value ? '#efefef' : 'inherit' }}
						onClick={() => {
							console.log('Logging out')
							logout()
						}}></Button>

				</div>
			</div>
			<Toast ref={toast} />
		</div>
	)
}

export default Topbar
