import React, { useState, useRef, useEffect, memo } from 'react'
// Primereact
import { Button } from 'primereact/button'
import { Chart } from 'primereact/chart'
// import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { InputTextarea } from 'primereact/inputtextarea'
// Utils
import { postIt, apiURL } from '../../utilities/network'
// Hooks
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'
// Constants
import { timelineOptions, dataOptions, eventOptions } from './constants'
import chartOptions from './chartOptions'
import useLocalStorage from 'use-local-storage'

const RowExpansionTemplate = ({ id, org }) => {
	const [chartLength, setChartLength] = useState('twoMonths')
	const [date, setDate] = useState(new Date())
	const [event, setEvent] = useState('')
	const [comment, setComment] = useState('')
	const [displayEventModal, setDisplayEventModal] = useState(false)
	const [product, setProduct] = useState({})
	const [deleteProductDialog, setDeleteProductDialog] = useState(false)
	const [dataCategories, setDataCategories] = useLocalStorage('dataCategories', ['advertised', 'f_spotify', 'streams-7day'])
	// const [dataCategories, setDataCategories] = useState(org === 'ls' ? ['advertised', 'f_spotify', 'streams-7day'] : ['advertised', 'f_spotify', 'listeners-7day'])

	const [playlistData, setPlaylistData] = useState([])
	const toast = useRef(null)
	const debounceLastKeystroke = useRef(0)
	const [note, setNote] = useState('')

	// Fetch playlist data
	const [{ data: res, error }] = useToast(useAxios(apiURL(`/playlist?id=${id}`)), { loading: 'Loading playlist from database...' })

	useEffect(() => {
		if (!res) {
			return
		}
		console.log(res)
		let sortedEvents = []
		if ('events' in res) {
			sortedEvents = res.events.sort((a, b) => {
				const c = new Date(a.date)
				const d = new Date(b.date)
				return d - c
			})
		}
		setPlaylistData({ data: res.data, events: sortedEvents })
		setNote(res?.notes || '')
	}, [res])

	if (!res) {
		return <p>Loading...</p>
	}

	if (error) {
		return <p>Error fetching data</p>
	}

	async function logEvent () {
		const res = await postIt('/logEvents', {
			events: [{
				id,
				data: {
					date,
					event,
					comment
				}
			}]
		})
		const newEvents = [...playlistData.events, { date, event, comment }]
		const newPlaylistData = { ...playlistData, events: newEvents }
		setPlaylistData(newPlaylistData)
		console.log(res)
		setDate('')
		setEvent('')
		setComment('')
		toast.current.show({ severity: 'success', summary: 'Logged Event', detail: 'Nice.', life: 3000 })
	}

	const rawDataObj = JSON.parse(JSON.stringify(res?.data || {}))
	if (rawDataObj?.seo_name && rawDataObj?.spotify_seo_name) {
		rawDataObj.seo_name = rawDataObj.seo_name.concat(rawDataObj.spotify_seo_name.map(obj => ({ date: obj.date, data: obj.data.rank })))
	}

	const chartData = {
		datasets: dataCategories.map((value) => {
			const dataObj = {
				label: res?.name,
				yAxisID: `y-${value}`
			}
			if (value === 'advertised') {
				dataObj.type = 'bar'
				dataObj.categoryPercentage = 1.0
				dataObj.barPercentage = 1.0
			} else {
				dataObj.type = 'line'
				dataObj.borderColor = dataOptions.find(obj => obj.value === value).color
				dataObj.cubicInterpolationMode = 'monotone'
			}

			dataObj.data = value in rawDataObj
				? rawDataObj[value]
					.map((val, i) => {
						return { x: val.date, y: typeof val.data === 'boolean' ? val.data === false ? 0 : 1 : val.data }
					})
					.filter(obj => {
						const today = new Date()
						const oneMonth = today.setMonth(today.getMonth() - 1)
						const twoMonths = today.setMonth(today.getMonth() - 2)
						const oneYear = today.setFullYear(today.getFullYear() - 1)
						const objDate = new Date(obj.x)
						switch (chartLength) {
							case 'month':
								return objDate > oneMonth
							case 'twoMonths':
								return objDate > twoMonths
							case 'year':
								return objDate > oneYear
							case 'all':
								return true
							default:
								break
						}
						return true
					})
				: []
			return dataObj
		})
	}

	const onHide = () => {
		setDisplayEventModal(false)
	}

	const hideDeleteProductDialog = () => {
		setDeleteProductDialog(false)
	}

	const confirmDeleteProduct = (product) => {
		setProduct(product)
		setDeleteProductDialog(true)
	}

	const deleteProduct = async () => {
		const newEvents = playlistData.events.filter(val => !(val.date === product.date && val.event === product.event && val.comment === product.comment))
		const newPlaylistData = { ...playlistData, events: newEvents }
		const res = await postIt('/logEvents?replace=true', { events: newEvents, id })
		console.log(res)
		setPlaylistData(newPlaylistData)
		setDeleteProductDialog(false)
		setProduct({})
		toast.current.show({ severity: 'success', summary: 'BALETED', detail: 'Event removed.', life: 3000 })
	}

	const deleteProductDialogFooter = (
		<React.Fragment>
			<Button label="Sorry nvm" className="p-button-outlined " onClick={hideDeleteProductDialog} />
			<Button label="Delete" icon="pi pi-times" className="p-button p-button-danger" onClick={deleteProduct} />
		</React.Fragment>
	)

	const footer = (
		<div>
			<Button label="Nah" icon="pi pi-times" onClick={onHide} className="p-button-outlined" />
			<Button label="Log it" icon="pi pi-check" onClick={() => {
				if (date === '' || event === '') {
					toast.current.show({ severity: 'warn', summary: 'Missing info', detail: 'Please enter date and event', life: 3000 })
					return
				}
				onHide()
				logEvent()
			}} />
		</div>
	)

	const onDataCategoryChange = (e) => {
		console.log(dataCategories)
		const selectedDataCategories = [...dataCategories]
		if (e.checked) { selectedDataCategories.push(e.value) } else { selectedDataCategories.splice(selectedDataCategories.indexOf(e.value), 1) }
		setDataCategories(selectedDataCategories)
	}

	const updateNote = async (field, note) => {
		debounceLastKeystroke.current = Date.now()
		setTimeout(async () => {
			if (Date.now() - debounceLastKeystroke.current > 1000) {
				const res = await postIt('/updatePlaylistProperty', { id, field: 'notes', value: note })
				console.log(res)
				toast.current.show({
					severity: 'success',
					summary: `${field} synced to database`,
					detail: `Notes for ${res?.name} updated.`,
					life: 3000
				})
			}
		}, 1000)
	}

	return (
		<div style={{ display: 'flex', width: '100%' }}>
			<div style={{ flex: 1, position: 'relative' }}>
				<Chart type="line" data={chartData} options={chartOptions} />
				<div style={{ display: 'flex' }}>
					<div style={{ flex: 1 }}>
						<h4 style={{ marginBottom: 5 }}>Timeline</h4>
						{
							timelineOptions.map((timelineOption) => {
								return (
									<div key={timelineOption.value} className="field-radiobutton" style={{ marginBottom: 5 }}>
										<RadioButton inputId={timelineOption.value} name="timelineOptions" value={timelineOption.value} onChange={(e) => {
											setChartLength(e.value)
										}} checked={chartLength === timelineOption.value} disabled={timelineOption.key === 'R'} />
										<label htmlFor={timelineOption.value} style={{ marginLeft: 5 }}>{timelineOption.label}</label>
									</div>
								)
							})
						}
					</div>
					<div style={{ flex: 1 }}>
						<h4 style={{ marginBottom: 5 }}>Data</h4>
						{
							dataOptions.map((dataOption) => {
								if (dataOption.value === 'advertised') {
									return ''
								}
								return (
									<div key={dataOption.value} style={{ marginBottom: 5 }}>
										<Checkbox inputId="cb1" value={dataOption.value} onChange={onDataCategoryChange} checked={dataCategories.includes(dataOption.value)}
										></Checkbox>
										<label htmlFor="cb1" className="p-checkbox-label" style={{ marginLeft: 5, color: dataOptions.find(obj => obj.value === dataOption.value).color }}>{dataOption.label}</label>
									</div>
								)
							})
						}
					</div>
				</div>

			</div>
			<div style={{ flex: 1, paddingLeft: 20, width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 10 }}>
						<h3 style={{ display: 'inline-block', marginTop: 0, marginBottom: 0, marginRight: 30 }}>Notes</h3>
					</div>
					<InputTextarea rows={5} style={{ width: '100%' }} value={note}
						onChange={(e) => {
							setNote(e.target.value)
							updateNote('notes', e.target.value)
						}} />
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 10 }}>
						<h3 style={{ display: 'inline-block', marginTop: 0, marginBottom: 0, marginRight: 30 }}>Events</h3>
						<Button
							type="button"
							icon="pi pi-plus" className="p-button-sm p-button-outlined"
							style={{ height: 28, width: 30 }}
							onClick={() => setDisplayEventModal(true)}></Button>
					</div>
					<DataTable value={playlistData?.events || []} responsiveLayout="scroll" style={{ width: '100%' }}>
						<Column field="date" style={{ maxWidth: 130 }} header="Date" body={rowData => new Date(rowData.date).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}></Column>
						<Column field="event" header="Event" style={{ maxWidth: 120 }}></Column>
						<Column field="comment" header="Details"></Column>
						<Column body={(rowData) =>
							<React.Fragment>
								<Button icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-danger" onClick={() => confirmDeleteProduct(rowData)} />
							</React.Fragment>
						} style={{ maxWidth: 70 }}></Column>
					</DataTable>
				</div>

			</div>
			<Toast ref={toast} />
			<Dialog visible={deleteProductDialog} style={{ width: '600px' }} header="Confirm Delete" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
				<div className="confirmation-content">
					<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
					{product && <span style={{ marginLeft: 20 }}>Are you sure you want to delete <b>{product?.event}</b> on <b>{'date' in product ? new Date(product.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'a day at some point'}</b>?</span>}
				</div>
			</Dialog>
			<Dialog header="New Event" visible={displayEventModal} style={{ width: '50vw', maxWidth: 400 }} footer={footer} onHide={() => onHide('displayBasic')}>
				<div style={{ marginBottom: 5 }}>
					<Calendar showIcon showButtonBar value={date} onChange={(e) => setDate(e.value)} placeholder="Date" style={{ width: 350 }}></Calendar>
				</div>
				<div style={{ marginBottom: 5, height: 40 }}>
					<Dropdown style={{ width: 350 }} value={event} options={eventOptions} onChange={(e) => setEvent(e.value)} placeholder="Event" />
				</div>
				<div style={{ marginBottom: 5 }}>
					<InputText
						type="text"
						onInput={(e) => setComment(e.target.value)}
						placeholder="Comment"
						style={{ width: 350 }}
					/>
				</div>
			</Dialog>
		</div >
	)
}

export default memo(RowExpansionTemplate)
